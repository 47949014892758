import React from "react";
import { useLocation } from "react-router-dom";

//do an api call to tasks with params for assignedRep and date and completed=false to determine the tasks of the day
//with a singular api call to

export default function PrintTasks() {
  // Hook to get location object
  const location = useLocation();

  // Function to parse query parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  // Use the custom hook to get query object
  const query = useQuery();

  // Extract `date` and `rep` query parameters
  const date = query.get("date");
  const rep = query.get("rep");

  return (
    <div>
      <h1>Print Tasks</h1>
      <p>Date: {date}</p>
      <p>Representative: {rep}</p>
    </div>
  );
}
