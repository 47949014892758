import React, { useEffect, useState } from "react";
import { getUserData2, getUserDayData } from "../../helpers/userActionHistory";

export default function AnalyticsTable2({ selectedSalesRep }) {
  const [totals, setTotals] = useState({
    dailyTotal: {
      callsMade: 0,
      emailsSent: 0,
      packagesSent: 0,
      demosBooked: 0,
    },
    weeklyTotal: {
      callsMade: 0,
      emailsSent: 0,
      packagesSent: 0,
      demosBooked: 0,
    },
    monthlyTotal: {
      callsMade: 0,
      emailsSent: 0,
      packagesSent: 0,
      demosBooked: 0,
    },
  });
  const [dayTotals, setDayTotals] = useState([]);

  useEffect(() => {
    // Fetch user data when the component mounts or when selectedSalesRep changes
    const fetchData = async () => {
      const data = await getUserData2(selectedSalesRep);
      if (data) {
        setTotals(data);
      }

      const dayData = await getUserDayData(selectedSalesRep);
      if (dayData) {
        setDayTotals(dayData);
      }
    };

    fetchData();
  }, [selectedSalesRep]);

  // Goals
  const dailyGoal = 50;
  const weeklyGoal = 250;
  const monthlyGoal = 1000;

  // Calculating totals and percentages
  const dailyKPI =
    totals.dailyTotal.callsMade +
    totals.dailyTotal.emailsSent +
    totals.dailyTotal.packagesSent +
    totals.dailyTotal.demosBooked;
  const weeklyKPI =
    totals.weeklyTotal.callsMade +
    totals.weeklyTotal.emailsSent +
    totals.weeklyTotal.packagesSent +
    totals.weeklyTotal.demosBooked;
  const monthlyKPI =
    totals.monthlyTotal.callsMade +
    totals.monthlyTotal.emailsSent +
    totals.monthlyTotal.packagesSent +
    totals.monthlyTotal.demosBooked;

  const dailyPercentage = ((dailyKPI / dailyGoal) * 100).toFixed(0);
  const weeklyPercentage = ((weeklyKPI / weeklyGoal) * 100).toFixed(0);
  const monthlyPercentage = ((monthlyKPI / monthlyGoal) * 100).toFixed(0);

  return (
    <div>
      <table className="table-auto border border-collapse w-full">
        <thead>
          <tr>
            <th className="border p-2"></th>
            <th className="border p-2">Calls</th>
            <th className="border p-2">Emails</th>
            <th className="border p-2">Packages</th>
            <th className="border p-2">Demos</th>
            <th className="border p-2 px-12">KPI</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border p-2">D</td>
            <td className="border p-2">{totals.dailyTotal.callsMade}</td>
            <td className="border p-2">{totals.dailyTotal.emailsSent}</td>
            <td className="border p-2">{totals.dailyTotal.packagesSent}</td>
            <td className="border p-2">{totals.dailyTotal.demosBooked}</td>
            <td className="border p-2">
              {dailyKPI}/{dailyGoal}{" "}
              <span
                className={`${dailyPercentage < 65 && "text-red-500 font-medium"}`}
              >
                {dailyPercentage}%
              </span>
            </td>
          </tr>
          <tr>
            <td className="border p-2">W</td>
            <td className="border p-2">{totals.weeklyTotal.callsMade}</td>
            <td className="border p-2">{totals.weeklyTotal.emailsSent}</td>
            <td className="border p-2">{totals.weeklyTotal.packagesSent}</td>
            <td className="border p-2">{totals.weeklyTotal.demosBooked}</td>
            <td className="border p-2">
              {weeklyKPI}/{weeklyGoal}{" "}
              <span
                className={`${weeklyPercentage < 65 && "text-red-500 font-medium"}`}
              >
                {weeklyPercentage}%
              </span>
            </td>
          </tr>
          <tr className="border-zinc-200 border-b-2">
            <td className="border p-2">M</td>
            <td className="border p-2">{totals.monthlyTotal.callsMade}</td>
            <td className="border p-2">{totals.monthlyTotal.emailsSent}</td>
            <td className="border p-2">{totals.monthlyTotal.packagesSent}</td>
            <td className="border p-2">{totals.monthlyTotal.demosBooked}</td>
            <td className="border p-2">
              {monthlyKPI}/{monthlyGoal}{" "}
              <span
                className={`${monthlyPercentage < 65 && "text-red-500 font-medium"}`}
              >
                {monthlyPercentage}%
              </span>
            </td>
          </tr>
          {dayTotals.map((dayTotal, index) => {
            // Trimming down day name to three characters
            const trimmedDay = dayTotal.day.substring(0, 3);
            const dayTotalKPI =
              dayTotal.callsMade +
              dayTotal.emailsSent +
              dayTotal.packagesSent +
              dayTotal.demosBooked;
            const dayTotalPercentage = (
              (dayTotalKPI / dailyGoal) *
              100
            ).toFixed(0);

            return (
              <tr key={index} className="">
                <td className="border p-2">{trimmedDay}</td>
                <td className="border p-2">{dayTotal.callsMade}</td>
                <td className="border p-2">{dayTotal.emailsSent}</td>
                <td className="border p-2">{dayTotal.packagesSent}</td>
                <td className="border p-2">{dayTotal.demosBooked}</td>
                <td className="border p-2">
                  {dayTotalKPI}/{dailyGoal}{" "}
                  <span
                    className={`${
                      dayTotalPercentage < 65 && "text-red-500 font-medium"
                    }`}
                  >
                    {dayTotalPercentage}%
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
