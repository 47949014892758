import React, { useState } from "react";
import * as XLSX from 'xlsx';

export default function MonthlyReportContent({
  data,
  salesReps,
  setSelectedRep,
  selectedRep,
  isAdmin,
}) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = Array.from({ length: 10 }, (_, i) => currentYear - i);

  const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
  const firstDayOfMonth = new Date(selectedYear, selectedMonth - 1, 1).getDay();


  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);  // Parse the date normally
    return (
      itemDate.getUTCFullYear() === selectedYear &&  // Use getUTCFullYear to avoid timezone shifts
      itemDate.getUTCMonth() + 1 === selectedMonth   // Use getUTCMonth for consistent month handling
    );
  });

  const userSpecificData = filteredData.flatMap((item) =>
    item.users
      .filter(
        (user) =>
          selectedRep === "" ||
          user.name.toLowerCase() === selectedRep.toLowerCase(),
      )
      .map((user) => ({
        ...user,
        date: item.date,
      })),
  );

  const totalStats = userSpecificData.reduce(
    (acc, user) => {
      acc.callsMade += user.callsMade || 0;
      acc.emailsSent += user.emailsSent || 0;
      acc.timesKicked += user.timesKicked || 0;
      acc.demosBooked += user.demosBooked || 0;
      acc.packagesSent += user.packagesSent || 0;
      acc.totalKPI +=
        (user.callsMade || 0) +
        (user.emailsSent || 0) +
        (user.demosBooked || 0) +
        (user.packagesSent || 0);

      return acc;
    },
    {
      callsMade: 0,
      emailsSent: 0,
      timesKicked: 0,
      packagesSent: 0,
      demosBooked: 0,
      totalKPI: 0,
    },
  );

  const calculateKPI = (user) => {
    const dailyKPI =
      (user.callsMade || 0) +
      (user.emailsSent || 0) +
      (user.packagesSent || 0) +
      (user.demosBooked || 0);
    const dailyPercentage = (dailyKPI / 50) * 100;

    return { dailyKPI, dailyPercentage };
  };

  const downloadExcel = () => {
    const wsData = [
      [`${selectedRep}'s Sales Report for ${months[selectedMonth - 1]}, ${selectedYear}`],
      [
        `Calls Made: ${totalStats.callsMade}`,
        `Emails Sent: ${totalStats.emailsSent}`,
        `Demos Booked: ${totalStats.demosBooked}`,
        `Packages Sent: ${totalStats.packagesSent}`,
        `Times Kicked: ${totalStats.timesKicked}`,
        `Total KPI: ${totalStats.totalKPI}`,
      ],
      [],
      [
        "Date",
        "Log In Time",
        "Log Out Time",
        "Calls Made",
        "Emails Sent",
        "Packages Sent",
        "Demos Booked",
        "Times Kicked",
        "Daily KPI",
        "Daily KPI Percentage",
      ],
    ];

    userSpecificData.forEach((user) => {
      const { dailyKPI, dailyPercentage } = calculateKPI(user);
      wsData.push([
        user.date,
        user.logInTime,
        user.logOutTime,
        user.callsMade || 0,
        user.emailsSent || 0,
        user.packagesSent || 0,
        user.demosBooked || 0,
        user.timesKicked || 0,
        dailyKPI,
        `${Math.round(dailyPercentage)}%`,
      ]);
    });

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Monthly Report");

    XLSX.writeFile(
      wb,
      `${selectedRep}_${months[selectedMonth - 1]}_${selectedYear}.xlsx`,
    );
  };

  // Create a calendar layout for the month
  const calendar = [];
  for (let i = 0; i < firstDayOfMonth; i++) {
    calendar.push(null); // Fill in blanks for days before the first of the month
  }
  for (let day = 1; day <= daysInMonth; day++) {
    const dateStr = `${selectedYear}-${String(selectedMonth).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    const dayData = userSpecificData.filter(user => user.date === dateStr);
    calendar.push({
      day,
      dayData,
    });
  }

  const weeks = [];
  for (let i = 0; i < calendar.length; i += 7) {
    weeks.push(calendar.slice(i, i + 7));
  }

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        {isAdmin && (
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              Sales Representative:
            </label>
            <select
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={selectedRep}
              onChange={(e) => setSelectedRep(e.target.value)}
            >
              {salesReps.map((rep, index) => (
                <option key={index} value={rep}>
                  {rep}
                </option>
              ))}
            </select>
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Month:
          </label>
          <select
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
          >
            {months.map((month, index) => (
              <option key={month} value={index + 1}>
                {month}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Year:</label>
          <select
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            value={selectedYear}
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-end justify-end col-span-1">
          <button
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md"
            onClick={downloadExcel}
          >
            Download Excel
          </button>
        </div>
      </div>

      {/* Calendar View */}
      <div className="grid grid-cols-7 gap-4">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="text-center font-bold">
            {day}
          </div>
        ))}
        {weeks.map((week, weekIndex) => (
          <React.Fragment key={weekIndex}>
            {week.map((day, dayIndex) =>
              day ? (
                <div key={dayIndex} className="border p-2">
                  <p className="font-bold mb-2">{day.day}</p>
                  {day.dayData.length > 0 ? (
                    <ul className="space-y-2">
                      {day.dayData.map((user, userIndex) => (
                        <li key={userIndex}>
                          <p><strong>LogIn:</strong> {user.logInTime}, <strong>LogOut:</strong> {user.logOutTime}</p>
                          <p><strong>Calls Made:</strong> {user.callsMade}</p>
                          <p><strong>Emails Sent:</strong> {user.emailsSent}</p>
                          <p><strong>Packages Sent:</strong> {user.packagesSent}</p>
                          <p><strong>Demos Booked:</strong> {user.demosBooked}</p>
                          <p><strong>Times Kicked:</strong> {user.timesKicked}</p>
                          <p><strong>Daily KPI:</strong> {calculateKPI(user).dailyKPI}/50</p>
                          <p><strong>KPI %:</strong> {Math.round(calculateKPI(user).dailyPercentage)}%</p>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No Data</p>
                  )}
                </div>
              ) : (
                <div key={dayIndex} className="border p-2"></div> // Blank day
              )
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
