// TimeDropdown.js

import React from "react";

function TimeDropdown({
  label,
  placeholder,
  options,
  selectedValue,
  onChange,
}) {
  return (
    <div className="w-1/2">
      <label
        htmlFor={label}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <select
        id={label}
        name={label}
        onChange={(e) => onChange(e.target.value)}
        value={selectedValue}
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
      >
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default TimeDropdown;
