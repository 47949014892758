import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import addHistory from "../historyHelper";
import {
  waitLetter1,
  waitLetter2,
  waitLetter3,
  waitLetter4,
  waitLetter5,
  waitLetter6,
  waitLetter7,
  waitLetter8,
  waitLetter9,
  waitLetter10,
  waitLetter11,
  waitLetter12,
} from "../scriptTemplates/emailTemplates";
import { initialCall, interimCall } from "../scriptTemplates/phoneTemplates";
import { increaseCount } from "../userActionHistory";

export async function checkIfWaitCallExists(data) {
  try {
    // Check if there are existing tasks with title 'Make Wait Call'
    const existingTasksResponse = await axios.get(
      `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${data.union}&localNumber=${data.localNumber}`,
    );

    const existingTasks = existingTasksResponse.data.filter(
      (task) => task.title === "Make Wait Call",
    );

    // If there are existing tasks with title 'Make Wait Call', return true
    if (existingTasks.length > 0) {
      return true;
    } else {
      // If no existing tasks found, return false
      return false;
    }
  } catch (error) {
    console.error("Error:", error);
    // Handle errors here
    return false; // Return false in case of an error
  }
}

export async function createWaitPhoneCall({
  data,
  nextContact,
  refreshSuspectList,
}) {
  try {
    // Check if there are existing tasks with title 'Make Wait Call'
    const existingTasksResponse = await axios.get(
      `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${data.union}&localNumber=${data.localNumber}`,
    );

    const existingTasks = existingTasksResponse.data.filter(
      (task) => task.title === "Make Wait Call",
    );

    // If there are existing tasks with title 'Make Wait Call', return without making the POST request
    if (existingTasks.length > 0) {
      console.log('Tasks with title "Make Wait Call" already exist.');
      return;
    }

    // If no existing tasks found, proceed to make the POST request
    const response = await axios.post(
      "https://unionapi.netlify.app/.netlify/functions/api/tasks",
      {
        _id: uuidv4(),
        completed: false,
        date: nextContact.toISOString(),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Before proceeding to the wait strategy, let's make a call${
          data.contactName
            ? ` to ${data.contactName}${data.contactJobTitle ? `(${data.contactJobTitle})` : ""}`
            : "."
        }`,
        endTime: "",
        startTime: "",
        title: "Make Wait Call",
        union: data.union,
        clientType: data.clientType,
        script: initialCall(data),
      },
    );

    // Handle the response, if needed
    console.log(response.data);

    // Call the refreshSuspectList function
    await refreshSuspectList();
  } catch (error) {
    console.error("Error:", error);
    // Handle errors here
  }
}

function getNextBusinessDay(date, days) {
  let count = 0;
  while (count < days) {
    date.setDate(date.getDate() + 1);
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      // Skip Sunday and Saturday
      count++;
    }
  }
  return date;
}

export async function createWaitTasks({ data, refreshSuspectList }) {
  const apiUrl = `https://unionapi.netlify.app/.netlify/functions/api/unions/${data._id}`;
  try {
    // Fetch the current union data
    const unionData = await fetchUnionData(data._id);

    await axios.put(apiUrl, {
      ...unionData,
      strategy: "wait",
      noContacts: 0,
      clientType: "Suspect",
    });
    // Refresh the suspect list

    //get all tasks and delete
    await getAllTasksAndDelete(
      `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${data.union}&localNumber=${data.localNumber}`,
    );
    const today = new Date();
    const tasksToCreate = [
      {
        _id: uuidv4(),
        completed: false,
        date: today.toISOString(),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Send wait letter 1 ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: `Send wait letter 1`,
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: waitLetter1(data),
        propertyToUpdate: "emailsSent",
      },
      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 3),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Send wait letter 2 ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: `Send wait letter 2`,
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: waitLetter2(data),
        propertyToUpdate: "emailsSent",
      },
      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 6),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Send wait letter 3 ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: `Send wait letter 3`,
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: waitLetter3(data),
        propertyToUpdate: "emailsSent",
      },
      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 9),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Interim phone call ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: "Interim phone call",
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: interimCall(data),
        propertyToUpdate: "callsMade",
      },

      //Set 2

      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 23),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Send wait letter 4 ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: `Send wait letter 4`,
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: waitLetter4(data),
        propertyToUpdate: "emailsSent",
      },
      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 26),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Send wait letter 5 ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: `Send wait letter 5`,
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: waitLetter5(data),
        propertyToUpdate: "emailsSent",
      },
      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 29),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Send wait letter 6 ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: `Send wait letter 6`,
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: waitLetter6(data),
        propertyToUpdate: "emailsSent",
      },
      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 32),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Interim phone call ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: "Interim phone call",
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: interimCall(data),
        propertyToUpdate: "callsMade",
      },

      //Set 3

      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 43),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Send wait letter 7 ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: `Send wait letter 7`,
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: waitLetter7(data),
        propertyToUpdate: "emailsSent",
      },
      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 46),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Send wait letter 8 ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: `Send wait letter 8`,
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: waitLetter8(data),
        propertyToUpdate: "emailsSent",
      },
      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 49),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Send wait letter 9 ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: `Send wait letter 9`,
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: waitLetter9(data),
        propertyToUpdate: "emailsSent",
      },
      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 52),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Interim phone call ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: "Interim phone call",
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: interimCall(data),
        propertyToUpdate: "callsMade",
      },

      //Set 4

      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 63),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Send wait letter 10 ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: `Send wait letter 10`,
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: waitLetter10(data),
        propertyToUpdate: "emailsSent",
      },
      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 66),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Send wait letter 11 ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: `Send wait letter 11`,
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: waitLetter11(data),
        propertyToUpdate: "emailsSent",
      },
      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 69),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Send wait letter 12 ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: `Send wait letter 12`,
        union: data.union,
        clientType: data.clientType,
        completable: true,
        script: waitLetter12(data),
        propertyToUpdate: "emailsSent",
      },
      {
        _id: uuidv4(),
        completed: false,
        date: getNextBusinessDay(new Date(), 72),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        contactNumber: "",
        contactPerson: "",
        description: `Interim phone call ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: "Interim phone call",
        union: data.union,
        clientType: data.clientType,
        completable:true,
        script: interimCall(data),
        propertyToUpdate: "callsMade",
      },
    ];
    increaseCount(data.assignedRep.toLowerCase(), "callsMade");
    addHistory({
      id: data._id,
      note: `Activated wait strategy`,
      reason: ``,
    });

    axios.post(
      "https://unionapi.netlify.app/.netlify/functions/api/tasks",
      tasksToCreate,
    );

    await refreshSuspectList();
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle any exceptions here
  }
}

async function fetchUnionData(id) {
  const apiUrl = `https://unionapi.netlify.app/.netlify/functions/api/unions/${id}`;
  const response = await axios.get(apiUrl);
  if (response.status !== 200) {
    throw new Error(`Failed to fetch union data. Status: ${response.status}`);
  }
  return response.data;
}

async function getAllTasksAndDelete(taskSearchUrl) {
  try {
    // Make an HTTP GET request to retrieve all tasks
    const taskSearchResponse = await axios.get(taskSearchUrl);

    if (taskSearchResponse.status === 200) {
      // Extract the list of tasks from the response
      const tasks = taskSearchResponse.data;

      // Iterate through the tasks and delete each one
      for (const task of tasks) {
        // Check if the title includes 'Pre-Election'
        if (!task.title.includes('Pre-Election')) {
          // Construct the URL to delete the task
          const taskDeleteUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks/${task._id}`;

          // Make an HTTP DELETE request to delete the task
          const taskDeleteResponse = await axios.delete(taskDeleteUrl);

          if (taskDeleteResponse.status === 200) {
            console.log(
              `Task DELETE request successful for task ID: ${task._id}`,
            );
          } else {
            console.error(`Task DELETE request failed for task ID: ${task._id}`);
          }
        } else {
          console.log(`Skipping task deletion for task ID: ${task._id} because title includes 'Pre-Election'`);
        }
      }
    } else {
      console.error(
        "Task search GET request failed:",
        taskSearchResponse.status,
        taskSearchResponse.data,
      );
      // Handle error cases for the task search GET request
    }
  } catch (error) {
    console.error("An error occurred while deleting tasks:", error);
    // Handle any exceptions here
  }
}

