// apiHelper.js

import axios from "axios";

const addHistory = ({ id, note, reason }) => {
  const unionUrl = `https://unionapi.netlify.app/.netlify/functions/api/unions/${id}`;

  // Fetch existing data
  return axios
    .get(unionUrl)
    .then(({ data: existingData }) => {
      // Perform the PUT request
      return axios.put(unionUrl, {
        ...existingData,
        history: [
          ...existingData.history,
          { note: note, reason: reason, date: new Date().toISOString() },
        ],
      });
    })
    .then((response) => {
      // Handle the response if needed
      console.log("Update Successful:", response.data);

      return response.data; // You can return any data from the response if needed
    })
    .catch((error) => {
      // Handle errors
      console.error("Error updating union:", error);
      throw error; // Propagate the error for the calling code to handle
    });
};

export default addHistory;
