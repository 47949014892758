import axios from "axios";

export function deleteLead(item) {
  return axios
    .delete(`https://unionapi.netlify.app/.netlify/functions/api/unions/${item._id}`)
    .then((response) => {
      if (response.status === 204) {
        console.log(`Lead ${item.union} ${item.localNumber} has been successfully deleted.`);
        deleteAllTasks({ data: item }); // Call deleteAllTasks here
      } else {
        console.error("Failed to delete the lead.");
        // You can return an error message or handle it as needed.
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
      // You can return an error message or handle it as needed.
    });
}

const deleteAllTasks = async ({ data }) => {
  try {
    const params = {
      localNumber: data.localNumber,
      union: data.union,
    };

    const response = await axios.get(
      "https://unionapi.netlify.app/.netlify/functions/api/tasks",
      { params },
    );

    if (response.data.length > 0) {
      const taskIdsToDelete = response.data.map((task) => task._id);

      await Promise.all(
        taskIdsToDelete.map((taskId) =>
          axios.delete(
            `https://unionapi.netlify.app/.netlify/functions/api/tasks/${taskId}`,
          ),
        ),
      );

      console.log("Tasks deleted successfully");
    } else {
      console.log("No matching tasks found");
    }
  } catch (error) {
    console.error("Error deleting tasks:", error);
  }
};
