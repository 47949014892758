// eventsHelper.js
import holidays from "../data/holidays.json";

export const filterEvents = () => {
  const currentDate = new Date();
  return holidays.holidays.filter((event) => {
    const dateType = event.date.type;
    if (dateType === "fixed" || dateType === "calculated") {
      if (event.start_date && event.end_date) {
        const startDateParts = event.start_date.split("-");
        const endDateParts = event.end_date.split("-");

        // Ensure valid start and end date parts
        if (startDateParts.length === 2 && endDateParts.length === 2) {
          const startDate = new Date(
            currentDate.getFullYear(),
            startDateParts[0] - 1,
            startDateParts[1],
          );
          let endDate = new Date(
            currentDate.getFullYear(),
            endDateParts[0] - 1,
            endDateParts[1],
          );

          endDate.setDate(endDate.getDate() + 1);

          return currentDate >= startDate && currentDate <= endDate;
        }
      }
    }
    return false;
  });
};
