import React from "react";

const EventCard = ({ name, description, date }) => {
  return (
    <div className="event-card bg-zinc-100 rounded-md p-2 shadow-md mb-2">
      <h3 className="text-xl font-bold">{name}</h3>
      {date && <p>{date.value}</p>}

      {description && <p>{description}</p>}
      <br />
      {/* Add any other content or styling for the event card */}
    </div>
  );
};

export default EventCard;
