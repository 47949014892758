import axios from "axios";
import { logoutUser } from "./logOut";

export async function createHistoryRecord(loggedInUser) {
  try {
    const today = new Date().toISOString().split("T")[0];

    // Check if a record with today's date already exists
    const existingRecordResponse = await axios.get(
      "https://unionapi.netlify.app/.netlify/functions/api/historycrm",
    );
    const existingRecord = existingRecordResponse.data.find(
      (record) => record.date === today,
    );

    if (existingRecord) {
      // Check if the logged in user is already present in the existing record
      const existingUserIndex = existingRecord.users.findIndex(
        (user) => user.name === loggedInUser,
      );

      if (existingUserIndex !== -1) {
        // If the user is already present
        const existingUser = existingRecord.users[existingUserIndex];

        if (!existingUser.logInTime) {
          // If the user exists but doesn't have a loginTime, update the loginTime
          const formattedLogInTime = new Date().toLocaleTimeString([], {
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          });

          existingUser.logInTime = formattedLogInTime;

          await axios.put(
            `https://unionapi.netlify.app/.netlify/functions/api/historycrm/${existingRecord._id}`,
            existingRecord,
          );
          console.log(
            "User login time updated in existing record:",
            existingRecord,
          );
        } else {
          console.log(
            "User already exists in today's record with a login time.",
          );
        }
      } else {
        // If the user is not present, add the user to the existing record and update it
        const formattedLogInTime = new Date().toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });

        existingRecord.users.push({
          name: loggedInUser,
          logInTime: formattedLogInTime,
        });

        await axios.put(
          `https://unionapi.netlify.app/.netlify/functions/api/historycrm/${existingRecord._id}`,
          existingRecord,
        );
        console.log("User added to existing record:", existingRecord);
      }
    } else {
      // If no record exists for today, create a new record and make the POST request
      const formattedLogInTime = new Date().toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });

      const newRecord = {
        date: today,
        users: [{ name: loggedInUser, logInTime: formattedLogInTime }],
      };

      await axios.post(
        "https://unionapi.netlify.app/.netlify/functions/api/historycrm",
        newRecord,
      );
      console.log("New record created:", newRecord);
    }
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function getUserData(rep) {
  // Define the API endpoint
  const apiUrl =
    "https://unionapi.netlify.app/.netlify/functions/api/historycrm";

  try {
    // Make an Axios GET request to the API
    const response = await axios.get(apiUrl);

    // Filter data for the past month
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const filteredData = response.data.filter(
      (entry) => new Date(entry.date) >= oneMonthAgo,
    );

    // Initialize daily, weekly, and monthly total objects
    let dailyTotal = {
      callsMade: 0,
      emailsSent: 0,
      packagesSent: 0,
      demosBooked: 0,
    };
    let weeklyTotal = {
      callsMade: 0,
      emailsSent: 0,
      packagesSent: 0,
      demosBooked: 0,
    };
    let monthlyTotal = {
      callsMade: 0,
      emailsSent: 0,
      packagesSent: 0,
      demosBooked: 0,
    };

    // Get today's date in the format "YYYY-MM-DD"
    const todayDate = new Date().toISOString().split("T")[0];

    // Iterate through filtered data to calculate totals for the specified rep
    filteredData.forEach((entry) => {
      const user = entry.users.find((user) => user.name === rep);

      if (user) {
        const callsMade = user.callsMade || 0;
        const emailsSent = user.emailsSent || 0;
        const packagesSent = user.packagesSent || 0;
        const demosBooked = user.demosBooked || 0;

        // Update daily total for the specific user
        if (entry.date === todayDate) {
          dailyTotal.callsMade += callsMade;
          dailyTotal.emailsSent += emailsSent;
          dailyTotal.packagesSent += packagesSent;
          dailyTotal.demosBooked += demosBooked;
        }

        // Update weekly total (within the past 7 days)
        const entryDate = new Date(entry.date);
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);

        if (entryDate >= sevenDaysAgo) {
          weeklyTotal.callsMade += callsMade;
          weeklyTotal.emailsSent += emailsSent;
          weeklyTotal.packagesSent += packagesSent;
          weeklyTotal.demosBooked += demosBooked;
        }

        // Update monthly total
        monthlyTotal.callsMade += callsMade;
        monthlyTotal.emailsSent += emailsSent;
        monthlyTotal.packagesSent += packagesSent;
        monthlyTotal.demosBooked += demosBooked;
      }
    });

    // Return the totals object
    return {
      dailyTotal,
      weeklyTotal,
      monthlyTotal,
    };
  } catch (error) {
    // Handle errors here
    console.error("Error fetching data:", error);
    return null;
  }
}


//getuserdata 2


export async function getUserData2(rep) {
  // Define the API endpoint
  const apiUrl =
    "https://unionapi.netlify.app/.netlify/functions/api/historycrm";
  const today = new Date();

  try {
    // Make an Axios GET request to the API
    const response = await axios.get(apiUrl);

    // Filter data for the past month
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    // const filteredData = response.data.filter(
    //   (entry) => new Date(entry.date) >= startOfMonth
    // );

    // Initialize daily, weekly, and monthly total objects
    let dailyTotal = {
      callsMade: 0,
      emailsSent: 0,
      packagesSent: 0,
      demosBooked: 0,
    };
    let weeklyTotal = {
      callsMade: 0,
      emailsSent: 0,
      packagesSent: 0,
      demosBooked: 0,
    };
    let monthlyTotal = {
      callsMade: 0,
      emailsSent: 0,
      packagesSent: 0,
      demosBooked: 0,
    };

    // Get today's date in the format "YYYY-MM-DD"
    const todayDate = today.toISOString().split("T")[0];

    // Calculate how many days to look back for weekly total based on the current day of the week
    const dayOfWeek = today.getDay(); // Sunday is 0, Monday is 1, and so on.
    let daysToSubtract;

    switch (dayOfWeek) {
      case 1: // Monday
        daysToSubtract = 1;
        break;
      case 2: // Tuesday
        daysToSubtract = 2;
        break;
      case 3: // Wednesday
        daysToSubtract = 3;
        break;
      case 4: // Thursday
        daysToSubtract = 4;
        break;
      case 5: // Friday
        daysToSubtract = 5;
        break;
      case 6: // Saturday
        daysToSubtract = 6;
        break;
      case 0: // Sunday
        daysToSubtract = 7;
        break;

      default:
        daysToSubtract=7;
        break;
    }

    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - daysToSubtract);

    // Debugging: log date range
    console.log(`Calculating weekly totals from ${startOfWeek.toISOString().split("T")[0]} to ${todayDate}`);

    // Iterate through filtered data to calculate totals for the specified rep
    response.data.forEach((entry) => {
      const entryDate = new Date(entry.date);
      const user = entry.users.find((user) => user.name === rep);

      if (user) {
        const callsMade = user.callsMade || 0;
        const emailsSent = user.emailsSent || 0;
        const packagesSent = user.packagesSent || 0;
        const demosBooked = user.demosBooked || 0;

        // Update daily total for the specific user
        if (entry.date === todayDate) {
          dailyTotal.callsMade += callsMade;
          dailyTotal.emailsSent += emailsSent;
          dailyTotal.packagesSent += packagesSent;
          dailyTotal.demosBooked += demosBooked;
        }

        // Update weekly total (within the past week based on the current day of the week)
        if (entryDate >= startOfWeek && entryDate <= today) {
          weeklyTotal.callsMade += callsMade;
          weeklyTotal.emailsSent += emailsSent;
          weeklyTotal.packagesSent += packagesSent;
          weeklyTotal.demosBooked += demosBooked;
        }

        // Update monthly total (within the current month)
        if (entryDate >= startOfMonth && entryDate <= today) {
          monthlyTotal.callsMade += callsMade;
          monthlyTotal.emailsSent += emailsSent;
          monthlyTotal.packagesSent += packagesSent;
          monthlyTotal.demosBooked += demosBooked;
        }
      }
    });

    // Debugging: log calculated totals
    console.log('Daily Total:', dailyTotal);
    console.log('Weekly Total:', weeklyTotal);
    console.log('Monthly Total:', monthlyTotal);

    // Return the totals object
    return {
      dailyTotal,
      weeklyTotal,
      monthlyTotal,
    };
  } catch (error) {
    // Handle errors here
    console.error("Error fetching data:", error);
    return null;
  }
}



export async function getUserDayData(rep) {
  // Assuming axios is available in your environment
  const response = await axios.get(
    "https://unionapi.netlify.app/.netlify/functions/api/historyCRM",
  );

  const initializeActivityData = () => ({
    callsMade: 0,
    emailsSent: 0,
    demosBooked: 0,
    packagesSent: 0,
  });

  // Correctly adjust the day index for naming, considering our data offset by one day
  const getAdjustedDayOfWeekName = (dayIndex) => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    // Adjust dayIndex to reflect the "next" day due to data being one day ahead
    return days[(dayIndex + 6) % 7]; // Shift index by one to get the "next" day's name
  };

  let weekData = [];

  // Adjust from Monday to today (excluding weekends and considering the shift)
  const today = new Date();
  const startOfWeek = new Date(today);
  startOfWeek.setDate(
    today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1),
  ); // Adjust to start from Monday of this week

  for (let d = new Date(startOfWeek); d < today; d.setDate(d.getDate() + 1)) {
    if (d.getDay() === 6 || d.getDay() === 0) continue; // Skip Sunday and Saturday

    const formattedDate = d.toISOString().split("T")[0];
    const matchingEntry = response.data.find(
      (entry) => entry.date === formattedDate,
    );
    const activityData = matchingEntry
      ? matchingEntry.users.find((user) => user.name === rep) ||
        initializeActivityData()
      : initializeActivityData();

    // Add data for the current day before incrementing d
    weekData.push({
      day: getAdjustedDayOfWeekName(d.getDay()),
      callsMade: activityData.callsMade || 0,
      emailsSent: activityData.emailsSent || 0,
      demosBooked: activityData.demosBooked || 0,
      packagesSent: 0,
    });
  }

  // Add today's data as the last entry, since the loop condition prevents it
  if (today.getDay() !== 0 && today.getDay() !== 6) {
    // Ensure today is not Sunday or Saturday
    const todayData = response.data.find(
      (entry) => entry.date === today.toISOString().split("T")[0],
    );
    const todayActivityData = todayData
      ? todayData.users.find((user) => user.name === rep) ||
        initializeActivityData()
      : initializeActivityData();
    weekData.push({
      day: getAdjustedDayOfWeekName(today.getDay()), // Use adjusted name for today
      callsMade: todayActivityData.callsMade || 0,
      emailsSent: todayActivityData.emailsSent || 0,
      demosBooked: todayActivityData.demosBooked || 0,
      packagesSent: 0,
    });
  }

  return weekData;
}

export async function storeLogOut(rep) {
  try {
    // Format today's date to match your API's date format using native JavaScript
    const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD

    // Fetch the history from the CRM API
    const response = await axios.get(
      "https://unionapi.netlify.app/.netlify/functions/api/historyCRM",
    );

    // Find an object with a date property matching today
    const matchingEntry = response.data.find((entry) => entry.date === today);

    if (matchingEntry) {
      // Find the user in the entry's users array whose name matches rep
      const userIndex = matchingEntry.users.findIndex(
        (user) => user.name === rep,
      );

      if (userIndex !== -1) {
        // Prepare the logOutTime string formatted as "1:23 PM" using native JavaScript
        const now = new Date();
        let hours = now.getHours();
        let minutes = now.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        const logOutTime = hours + ":" + minutes + " " + ampm;

        // Update the user object within the users array
        matchingEntry.users[userIndex].logOutTime = logOutTime;

        // Now update the entire entry with the modified users array
        await axios.put(
          `https://unionapi.netlify.app/.netlify/functions/api/historyCRM/${matchingEntry._id}`,
          matchingEntry,
        );

        console.log("User logOutTime updated successfully to:", logOutTime);
      } else {
        console.log("User not found in today's entry.");
      }
    } else {
      console.log("No matching entry found for today.");
    }
  } catch (error) {
    console.error("Error in storeLogOut function:", error);
  }
}

export async function getLoginTime(rep) {
  try {
    const response = await axios.get(
      "https://unionapi.netlify.app/.netlify/functions/api/historycrm",
    );

    // Assuming the response data is an array of objects with a 'date' property
    const today = new Date().toISOString().split("T")[0]; // Get today's date in the format 'YYYY-MM-DD'

    const loginTimeObject = response.data.find((item) => item.date === today);

    if (loginTimeObject && loginTimeObject.users) {
      const userObject = loginTimeObject.users.find(
        (user) => user.name.toLowerCase() === rep.toLowerCase(),
      );

      if (userObject) {
        console.log(userObject);
        return userObject;
      } else {
        console.log(`User with name ${rep} not found in loginTimeObject.users`);
        return null;
      }
    } else {
      console.log("Invalid loginTimeObject structure");
      return null;
    }
  } catch (error) {
    console.error("Error fetching login time:", error);
    throw error; // You might want to handle this error in your application
  }
}

export async function increaseCount(selectedSalesRep, property) {
  try {
    const today = new Date().toISOString().split("T")[0];

    const existingRecordResponse = await axios.get(
      "https://unionapi.netlify.app/.netlify/functions/api/historycrm",
    );
    const existingRecord = existingRecordResponse.data.find(
      (record) => record.date === today,
    );

    if (existingRecord) {
      const userWithName = existingRecord.users.find(
        (user) => user.name === selectedSalesRep,
      );

      if (userWithName) {
        // If the user exists, increase the specified property by 1
        userWithName[property] = (userWithName[property] || 0) + 1;
      } else {
        // If the user doesn't exist, add a new user with the specified name and set the property to 1
        existingRecord.users.push({ name: selectedSalesRep, [property]: 1 });
      }

      // Update the existing record with an Axios put request
      await axios.put(
        `https://unionapi.netlify.app/.netlify/functions/api/historycrm/${existingRecord._id}`,
        existingRecord,
      );

      console.log(
        `User ${selectedSalesRep}'s ${property} increased to:`,
        userWithName ? userWithName[property] : 1,
      );
    } else {
      // If no record exists for today's date, create a new record with a user containing the specified property set to 1
      const newRecord = {
        date: today,
        users: [{ name: selectedSalesRep, [property]: 1 }],
      };

      // Instead of doing a post, update the existing record with an Axios put request
      await axios.put(
        "https://unionapi.netlify.app/.netlify/functions/api/historycrm",
        newRecord,
      );

      console.log(
        `No record found for today's date. Creating a new record with ${property} set to 1.`,
      );
    }

    // Add your logic here to handle state update or any other necessary logic accordingly
  } catch (error) {
    console.error("Error fetching or updating existing records:", error);
  }
}

export async function increaseTimesKicked() {
  try {
    // Get the selected sales representative from local storage
    const selectedSalesRep = localStorage.getItem("loggedInUser");

    // Check if a sales representative is selected
    if (!selectedSalesRep) {
      console.log("No sales representative selected. Aborting function.");
      return;
    }

    await storeLogOut(selectedSalesRep);

    const normalizedSalesRep = selectedSalesRep.toLowerCase();
    const today = new Date().toISOString().split("T")[0];

    const existingRecordResponse = await axios.get(
      "https://unionapi.netlify.app/.netlify/functions/api/historycrm",
    );
    const existingRecord = existingRecordResponse.data.find(
      (record) => record.date === today,
    );

    if (existingRecord) {
      const userWithName = existingRecord.users.find(
        (user) => user.name === normalizedSalesRep,
      );

      if (userWithName) {
        // If the user exists, increase the timesKicked property by 1
        userWithName.timesKicked = (userWithName.timesKicked || 0) + 1;
      } else {
        // If the user doesn't exist, add a new user with the specified name and set the timesKicked property to 1
        existingRecord.users.push({ name: normalizedSalesRep, timesKicked: 1 });
      }

      // Update the existing record with an Axios put request
      await axios.put(
        `https://unionapi.netlify.app/.netlify/functions/api/historycrm/${existingRecord._id}`,
        existingRecord,
      );

      console.log(
        `User ${normalizedSalesRep}'s timesKicked increased to:`,
        userWithName ? userWithName.timesKicked : 1,
      );
      await logoutUser();
    } else {
      // If no record exists for today's date, create a new record with a user containing the timesKicked property set to 1
      const newRecord = {
        date: today,
        users: [{ name: normalizedSalesRep, timesKicked: 1 }],
      };

      // Instead of doing a post, update the existing record with an Axios put request
      await axios.put(
        "https://unionapi.netlify.app/.netlify/functions/api/historycrm",
        newRecord,
      );

      console.log(
        `No record found for today's date. Creating a new record with timesKicked set to 1.`,
      );
    }

    // Add your logic here to handle state update or any other necessary logic accordingly
  } catch (error) {
    console.error("Error fetching or updating existing records:", error);
  }
}
