import React, { useState } from "react"; 
import Modal from "./Modal";
import Calendar from "../Calendar";

import { formatDate, nextBusinessDay } from "../../helpers/formatDate";
import { RedButton, GreenButton } from "../buttons/button";
import { rescheduleCall } from "../../helpers/strategies/infopak";

export default function RescheduleInitialCallModal({
  closeModal,
  data,
  refreshSuspectList,
  tasks,
}) {
  const [rescheduleDays, setRescheduleDays] = useState("1");
  const [showCalendar, setShowCalendar] = useState(false);
  const [isFullModal, setIsFullModal] = useState(false);
  const [scheduleDate, setScheduleDate] = useState();

  const handleRadioChange = (e) => {
    setRescheduleDays(e.target.value);
  };

  const handleShowCalendar = () => {
    setShowCalendar(true);
    setIsFullModal(true);
  };

  const handleCloseCalendar = () => {
    setShowCalendar(false);
    setIsFullModal(false);
  };

  const confirmReschedule = ({ data, nextContact }) => {
    closeModal();
    rescheduleCall({
      data: data,
      nextContact: nextContact,
      refreshSuspectList: refreshSuspectList,
    });
  };

  return (
    <Modal closeModal={closeModal} fullModal={isFullModal}>
      <h2 className="text-xl font-bold">Reschedule this call</h2>
      {true ? (
        <>
          {showCalendar ? (
            <Calendar
              tasks={tasks}
              onCloseCalendar={handleCloseCalendar}
              setScheduleDate={setScheduleDate}
            />
          ) : (
            <div>
              {scheduleDate ? (
                <div className="gap-x-2 flex items-center">
                  <p>
                    Call back will be scheduled to:{" "}
                    <span className="font-bold">
                      {formatDate(scheduleDate)}
                    </span>
                  </p>
                  <button
                    className="bg-red-500 p-2 rounded-lg text-white font-bold"
                    onClick={() => setScheduleDate(null)}
                  >
                    Change
                  </button>
                </div>
              ) : (
                <div className="flex gap-x-16 justify-start items-center">
                  <div className="">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Reschedule in how many days?
                    </label>
                    <div className="flex">
                      {[1, 2, 3, 4, 5].map((day) => (
                        <div key={day} className="mr-4">
                          <input
                            type="radio"
                            id={`${day}Day`}
                            name="rescheduleDays"
                            value={day.toString()}
                            checked={rescheduleDays === day.toString()}
                            onChange={handleRadioChange}
                            className="mr-1"
                          />
                          <label htmlFor={`${day}Day`}>{day}</label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="">Or</div>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleShowCalendar}
                  >
                    Select Date
                  </button>
                </div>
              )}
              <div className="flex gap-x-2 mt-16 justify-end">
                <RedButton action={closeModal}>Cancel</RedButton>
                <GreenButton
                  action={() =>
                    confirmReschedule({
                      data: data,
                      nextContact:
                        scheduleDate || nextBusinessDay(rescheduleDays),
                    })
                  }
                >
                  Reschedule Call Back
                </GreenButton>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="flex gap-x-2 mt-16 justify-end">
            <RedButton action={closeModal}>Cancel</RedButton>
            <GreenButton
              action={() =>
                confirmReschedule({
                  data: data,
                  nextContact: scheduleDate || nextBusinessDay(rescheduleDays),
                })
              }
            >
              Activate Active Approach
            </GreenButton>
          </div>
        </>
      )}
    </Modal>
  );
}
