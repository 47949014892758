import React, { useEffect, useState } from "react";
import Navbar from "../components/navigation/Navbar";
import { useParams } from "react-router-dom";
import axios from "axios";

import EditModal from "../components/modals/EditModal";
import "../css/button.css";
import NoModal from "../components/modals/NoModal";
import NoContactModal from "../components/modals/NoContactModal";
// import NoContactAppointmentModal from '../components/modals/NoContactAppointmentModal';
import YesModal from "../components/modals/YesModal";
import NewClientModal from "../components/modals/NewClientModal";

import RescheduleModal from "../components/modals/RescheduleModal";
import LeadPageContent from "../components/content/LeadPageContent";
import MakeProspectModal from "../components/modals/MakeProspectModal";
import MoveToCallCalendarModal from "../components/modals/MoveToCallCalendarModal";
import WaitModal from "../components/modals/WaitModal";
import NoContactAfterDemoModal from "../components/modals/NoContactAfterDemoModal";
import WaitAfterDemoModal from "../components/modals/WaitAfterDemoModal";

import RescheduleInitialCallModal from "../components/modals/RescheduleInitialCallModal";

export default function LeadPage() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [unionLocal, setUnionLocal] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNoModalOpen, setIsNoModalOpen] = useState(false);
  const [isYesModalOpen, setIsYesModalOpen] = useState(false);
  const [isWaitModalOpen, setIsWaitModalOpen] = useState(false);
  const [isNoContactModalOpen, setIsNoContactModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [isRescheduleInitialCallModalOpen, setIsRescheduleInitialCallModalOpen] = useState(false);
  const [isMakeProspectModalOpen, setIsMakeProspectModalOpen] = useState(false);
  const [isMoveToCallCalendarModalOpen, setIsMoveToCallCalendarModalOpen] =
    useState(false);
  const [isNoContactAfterDemoModalOpen, setIsNoContactAfterDemoModalOpen] =
    useState(false);
  const [isWaitAfterDemoModalOpen, setIsWaitAfterDemoModalOpen] =
    useState(false);
  const [tasks, setTasks] = useState([]);

  const fetchTasks = (union, localNumber) => {
    axios
      .get("https://unionapi.netlify.app/.netlify/functions/api/tasks", {
        params: {
          union,
          localNumber,
        },
      })
      .then((response) => {
        setTasks(response.data);
      })
      .catch((error) => {
        console.error("Error fetching tasks:", error);
      });
  };

  useEffect(() => {
    if (!id) return;

    const fetchData = () => {
      axios
        .get(`https://unionapi.netlify.app/.netlify/functions/api/unions/${id}`)
        .then((response) => {
          setData(response.data);
          setUnionLocal(`${response.data.union} ${response.data.localNumber}`);
          fetchTasks(response.data.union, response.data.localNumber);
        })
        .catch((error) => {
          console.log(error.message);
        });
    };

    fetchData();
  }, [id]);

  const refreshSuspectList = () => {
    if (!id) return;
    //fetchTasks();
    axios
      .get(`https://unionapi.netlify.app/.netlify/functions/api/unions/${id}`)
      .then((response) => {
        setData(response.data);
        setUnionLocal(`${response.data.union} ${response.data.localNumber}`);
        fetchTasks(response.data.union, response.data.localNumber);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  return (
    <div>
      <Navbar />
      {isEditModalOpen && (
        <EditModal
          closeModal={() => setIsEditModalOpen(false)}
          clientData={data}
          refreshSuspectList={refreshSuspectList}
          tasks={tasks}
          setData={setData}
        />
      )}
      {isNoModalOpen && (
        <NoModal
          closeModal={() => setIsNoModalOpen(false)}
          refreshSuspectList={refreshSuspectList}
          data={data}
          tasks={tasks}
        />
      )}
      {isNoContactModalOpen &&
        (() => {
          switch (data.strategy) {
            case "infopak":
              return (
                <NoContactModal
                  closeModal={() => setIsNoContactModalOpen(false)}
                  refreshSuspectList={refreshSuspectList}
                  data={data}
                  tasks={tasks}
                />
              );
            case "appointment":
              return (
                <NoContactModal
                  closeModal={() => setIsNoContactModalOpen(false)}
                  refreshSuspectList={refreshSuspectList}
                  data={data}
                  tasks={tasks}
                />
              );
            case "active":
              return (
                <NoContactModal
                  closeModal={() => setIsNoContactModalOpen(false)}
                  refreshSuspectList={refreshSuspectList}
                  data={data}
                  tasks={tasks}
                />
              );
            case "wait":
              return (
                <NoContactModal
                  closeModal={() => setIsNoContactModalOpen(false)}
                  refreshSuspectList={refreshSuspectList}
                  data={data}
                  tasks={tasks}
                />
              );
            // Add more cases as needed
            default:
              return null; // Render nothing if no matching case is found
          }
        })()}
      {isWaitModalOpen && (
        <WaitModal
          closeModal={() => setIsWaitModalOpen(false)}
          refreshSuspectList={refreshSuspectList}
          data={data}
          tasks={tasks}
        />
      )}
      {isYesModalOpen &&
        (() => {
          switch (data.strategy) {
            case "infopak":
              return (
                <YesModal
                  closeModal={() => setIsYesModalOpen(false)}
                  refreshSuspectList={refreshSuspectList}
                  data={data}
                  tasks={tasks}
                />
              );
            case "appointment":
              return (
                <NewClientModal
                  closeModal={() => setIsYesModalOpen(false)}
                  refreshSuspectList={refreshSuspectList}
                  data={data}
                />
              );
            case "active":
              return (
                <YesModal
                  closeModal={() => setIsYesModalOpen(false)}
                  refreshSuspectList={refreshSuspectList}
                  data={data}
                  tasks={tasks}
                />
              );
            case "wait":
              return (
                <YesModal
                  closeModal={() => setIsYesModalOpen(false)}
                  refreshSuspectList={refreshSuspectList}
                  data={data}
                  tasks={tasks}
                />
              );
            default:
              return null; // Handle other cases or provide a default behavior
          }
        })()}

      {isMoveToCallCalendarModalOpen && (
        <MoveToCallCalendarModal
          closeModal={() => setIsMoveToCallCalendarModalOpen(false)}
          refreshSuspectList={refreshSuspectList}
          data={data}
          tasks={tasks}
        />
      )}

      {isNoContactAfterDemoModalOpen && (
        <NoContactAfterDemoModal
          closeModal={() => setIsNoContactAfterDemoModalOpen(false)}
          refreshSuspectList={refreshSuspectList}
          data={data}
          tasks={tasks}
          refreshTasks={fetchTasks}
        />
      )}

      {isWaitAfterDemoModalOpen && (
        <WaitAfterDemoModal
          closeModal={() => setIsWaitAfterDemoModalOpen(false)}
          refreshSuspectList={refreshSuspectList}
          data={data}
          tasks={tasks}
          refreshTasks={fetchTasks}
        />
      )}

      {isMakeProspectModalOpen && (
        <MakeProspectModal
          closeModal={() => setIsMakeProspectModalOpen(false)}
          refreshSuspectList={refreshSuspectList}
          data={data}
          tasks={tasks}
        />
      )}

      {isRescheduleModalOpen && (
        <RescheduleModal
          closeModal={() => setIsRescheduleModalOpen(false)}
          refreshSuspectList={refreshSuspectList}
          data={data}
          tasks={tasks}
        />
      )}

      {isRescheduleInitialCallModalOpen && (
        <RescheduleInitialCallModal
          closeModal={() => setIsRescheduleInitialCallModalOpen(false)}
          refreshSuspectList={refreshSuspectList}
          data={data}
          tasks={tasks}
        />
      )}

      <LeadPageContent
        data={data}
        unionLocal={unionLocal}
        setIsNoContactModalOpen={setIsNoContactModalOpen}
        setIsNoModalOpen={setIsNoModalOpen}
        setIsRescheduleModalOpen={setIsRescheduleModalOpen}
        setIsRescheduleInitialCallModalOpen={setIsRescheduleInitialCallModalOpen}
        setIsYesModalOpen={setIsYesModalOpen}
        setIsMakeProspectModalOpen={setIsMakeProspectModalOpen}
        setIsWaitModalOpen={setIsWaitModalOpen}
        setIsMoveToCallCalendarModalOpen={setIsMoveToCallCalendarModalOpen}
        setIsNoContactAfterDemoModalOpen={setIsNoContactAfterDemoModalOpen}
        setIsWaitAfterDemoModalOpen={setIsWaitAfterDemoModalOpen}
        handleEditModalOpen={handleEditModalOpen}
        refreshTasks={fetchTasks}
        tasks={tasks}
      />
    </div>
  );
}
