export function formatSpelledDate(isoDate) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(isoDate);
  return date.toLocaleDateString("en-US", options);
}

export function subtractBusinessDays(date, days) {
  let count = 0;
  while (count < days) {
    date.setDate(date.getDate() - 1);
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      // Skip Sunday and Saturday
      count++;
    }
  }
  return date;
}

export function formatDate(isoDate) {
  if (!isoDate) return ""; // Handle null or undefined input

  const date = new Date(isoDate);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 because months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}
export function nextBusinessDay(number) {
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
  const today = new Date();

  for (let i = 0; i < number; ) {
    today.setTime(today.getTime() + oneDay); // Move to the next day

    // Check if the current day is a weekend (Saturday or Sunday)
    const dayOfWeek = today.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      // If it's not a weekend, increment the count of business days
      i++;
    }
  }

  return today;
}

export function formatDateWithTime(isoDate) {
  if (!isoDate) return ""; // Handle null or undefined input

  const date = new Date(isoDate);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 because months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  const time = `${hours}:${minutes} ${ampm}`;

  return `${month}/${day}/${year} ${time}`;
}
