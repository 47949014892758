import React from "react";
import EventCard from "./EventCard";

export default function Events({ events }) {
  // Create an object to group events by month
  const eventsByMonth = events.reduce((acc, event) => {
    const month = event.date.month;
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(event);
    return acc;
  }, {});

  return (
    <div>
      {Object.keys(eventsByMonth).map((month, index) => (
        <div key={index}>
          <h2 className="text-xl font-bold mt-4 mb-2">{month}</h2>
          {eventsByMonth[month].map((event, eventIndex) => (
            <EventCard
              key={eventIndex}
              name={event.name}
              description={event.description}
              date={event.date}
            />
          ))}
        </div>
      ))}
      {events.length === 0 && <h2 className="text-xl">No Events</h2>}
    </div>
  );
}
