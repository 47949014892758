import React, { useEffect, useState } from "react";
import { getLoginTime } from "../../helpers/userActionHistory";

export default function Analytics({ selectedSalesRep }) {
  const [userHistoryData, setUserHistoryData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getLoginTime(selectedSalesRep);
        setUserHistoryData(result);
      } catch (error) {
        // Handle the error, e.g., show an error message to the user
        console.error("Error fetching login time:", error);
      }
    };

    fetchData();
  }, [selectedSalesRep]);

  return (
    <div>
      {userHistoryData && (
        <div>
          <p>Login Time: {userHistoryData.logInTime}</p>
          {userHistoryData.timesKicked && (
            <p>Times Kicked: {userHistoryData.timesKicked}</p>
          )}
          {userHistoryData.logOutTime && (
            <p>Log Out: {userHistoryData.logOutTime}</p>
          )}
        </div>
        // Replace "loginTime" with the actual property you want to display
      )}
    </div>
  );
}
