import axios from "axios";
import { v4 as uuidv4 } from "uuid";
// import { noLetter1 } from "./scriptTemplates/emailTemplates";

export async function resetLeadHistory(id) {
  const apiUrl = `https://unionapi.netlify.app/.netlify/functions/api/unions/${id}`;

  // Define the data you want to send in the PUT request
  const requestData = {
    noContacts: 0,
    strategy: "infopak",
    clientType: "Sleep",
  };

  try {
    // Make the PUT request with Axios
    const response = await axios.put(apiUrl, requestData);

    // Check the response and handle it accordingly
    if (response.status === 200) {
      console.log("Lead history reset successfully.");
      // You can also return some data from the response if needed
      return response.data;
    } else {
      console.error("Failed to reset lead history.");
      // Handle the error here
    }
  } catch (error) {
    console.error("An error occurred while making the PUT request:", error);
    // Handle the error here
  }
}

export const activateSleepStrategy = async ({
  data,
  setIsButtonDisabled,
  deleteAllTasks,
  wakeUpCall,
  updateHistory,
  closeModal,
  refreshSuspectList,
}) => {
  setIsButtonDisabled(true); // Disable the button
  resetLeadHistory(data._id);

  try {
    await deleteAllTasks();

    const note = document.getElementById("note").value;
    const nextContactValue = document.getElementById("nextContact").value;

    const currentDate = new Date();
    let newDate = new Date();
    if (nextContactValue === "1 month") {
      newDate.setMonth(currentDate.getMonth() + 1);
    } else if (nextContactValue === "3 months") {
      newDate.setMonth(currentDate.getMonth() + 3);
    } else if (nextContactValue === "6 months") {
      newDate.setMonth(currentDate.getMonth() + 6);
    } else if (nextContactValue === "1 year") {
      newDate.setFullYear(currentDate.getFullYear() + 1);
    }

    const taskArray = [
      {
        _id: uuidv4(),
        title: "Awaken Call",
        description: note,
        localNumber: data.localNumber,
        union: data.union,
        contactPerson: data.contactName,
        contactNumber: data.contactNumber1
          ? data.contactNumber1
          : data.officePhoneNumber,
        contactEmail: data.contactEmail ? data.contactEmail : data.email,
        startTime: "",
        endTime: "",
        completed: false,
        date: newDate.toISOString(),
        assignedRep: data.assignedRep,
        __v: 0,
        link: data._id,
        clientType: data.clientType,
        script: wakeUpCall(data),
      },
      // {
      //   _id: uuidv4(),
      //   completed: false,
      //   date: newDate.toISOString(),
      //   __v: 0,
      //   localNumber: data.localNumber,
      //   assignedRep: data.assignedRep,
      //   link: data._id,
      //   contactEmail: data.contactEmail?data.contactEmail:data.email,
      //   contactNumber: "",
      //   contactPerson: "",
      //   description: `Send "No Email" ${data.contactName ? ` to ${data.contactName} (${data.contactJobTitle})` : ''}`,
      //   endTime: "",
      //   startTime: "",
      //   title: `Send No Email`,
      //   union: data.union,
      //   clientType: data.clientType,
      //   completable: true,
      //   script:noLetter1(data),
      //   propertyToUpdate:"emailsSent"
      // }
      // Add additional tasks as needed
      // { /* Another task object */ },
    ];

    await Promise.all(
      taskArray.map(async (task) => {
        await axios.post(
          "https://unionapi.netlify.app/.netlify/functions/api/tasks",
          task,
        );
        console.log("Task added successfully");
      }),
    );

    await updateHistory(data._id, note, nextContactValue);
  } catch (error) {
    console.error("Error adding tasks:", error);
  } finally {
    closeModal();
    refreshSuspectList();
    setIsButtonDisabled(false); // Enable the button after completion
  }
};
