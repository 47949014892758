import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../helpers/formatDate";
import { completeTask } from "../helpers/taskActions";
import { FaCheck, FaEdit } from "react-icons/fa";
import TaskCompleteModal from "./modals/TaskCompleteModal";

export default function TaskComponent({
  task,
  toggleDescription,
  isDescriptionExpanded,
}) {
  const [isTaskCompleteModalOpen, setIsTaskCompleteModalOpen] = useState(false);

  const isAdmin = localStorage.getItem("isAdmin");

  const reloadFunction = () => {
    window.location.reload();
  };

  const closeTaskCompleteModal = () => {
    setIsTaskCompleteModalOpen(false);
  };

  const confirmCompleteTask = () => {
    console.log('task is', task)
    //we are passing the id of the task that is being completed, the reload function which refreshes page, rep which is loggedin user and property, example emailsSent,callsMade
    completeTask({
      id: task?._id,
      reloadFunction: reloadFunction,
      rep: task?.assignedRep.toLowerCase(),
      property: task?.propertyToUpdate,
      task: task,
    });
    closeTaskCompleteModal();
  };

  const taskNotCompleted = () => {
    closeTaskCompleteModal();
  };

  const handleCompletedClick = () => {
    if (!task?.completed) {
      setIsTaskCompleteModalOpen(true);
    }
  };

  return (
    <>
      {!task?.completed && (
        <div
          key={task?._id}
          className={`rounded-xl m-2 p-2 ${
            task?.clientType && `bg-${task?.clientType.toLowerCase()}`
          }`}
        >
          <div className="block font-bold">{task.clientType}</div>
          <Link
            to={`/lead/${task?.link}`}
            className="text-primary font-bold hover:text-primary-dark hover:underline"
          >
            <span>
              {task?.union} {task?.localNumber}{" "}
              {isAdmin && task?.assignedRep && `(${task?.assignedRep})`}
            </span>
          </Link>

          <div className="flex gap-x-4">
            <p className="font-light ">{formatDate(task?.date)}</p>
            {/* Add more task-related information */}
          </div>

          <div className="bg-white rounded-md p-1">
            <p className="text-black font-bold">{task?.title}</p>
            {task?.description.length > 100 ? (
              <div>
                {isDescriptionExpanded ? (
                  <div>
                    {task?.description}{" "}
                    <span
                      className="text-blue-500 cursor-pointer"
                      onClick={() => toggleDescription(task?._id)}
                    >
                      Show Less
                    </span>
                  </div>
                ) : (
                  <div>
                    {`${task?.description.slice(0, 100)} `}
                    <span
                      className="text-blue-500 cursor-pointer"
                      onClick={() => toggleDescription(task?._id)}
                    >
                      Read More
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div>{task?.description}</div>
            )}
          </div>

          {task?.completable === true && (
            <button
              onClick={handleCompletedClick}
              className="flex items-center justify-center px-4 py-2 bg-green-500 text-white rounded-md focus:outline-none focus:shadow-outline-green active:bg-green-600 my-2"
            >
              <FaCheck className="mr-2" /> Complete Task
            </button>
          )}

          {(task?.title.includes('Awaken')||task?.title.includes('Initial Call')) && (
            <Link
            to={`/lead/${task?.link}?edit=true`}
              className="flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-md focus:outline-none focus:shadow-outline-green active:bg-blue-600 my-2"
            >
              <FaEdit className="mr-2" /> Open Lead Info
            </Link>
          )}
        </div>
      )}

      {isTaskCompleteModalOpen && (
        <TaskCompleteModal
          confirmCompleteTask={confirmCompleteTask}
          taskNotCompleted={taskNotCompleted}
          task={task}
        />
      )}
    </>
  );
}
