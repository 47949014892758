import React, { useState } from "react";
import { Link } from "react-router-dom";
import { filterEvents } from "../../helpers/eventUtils";
import {
  initialCall,
  wakeUpCall,
  interimCall,
  confirmDemo,
} from "../../helpers/scriptTemplates/phoneTemplates";
import ScriptModal from "../modals/ScriptModal";
import Events from "./Events";
import { storeLogOut } from "../../helpers/userActionHistory";

const Navbar = ({ loggedInUser }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScriptModalOpen, setIsScriptModalOpen] = useState(false);
  const [isEventsSidebarOpen, setIsEventsSidebarOpen] = useState(false);
  const [selectedScript, setSelectedScript] = useState({});

  const openModal = () => {
    console.log("Opening Modal");
    setIsModalOpen(true);
    // Close the Events sidebar when Scripts modal is opened
    setIsEventsSidebarOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openScriptModal = ({ message, voiceMail, subject }) => {
    setSelectedScript({ message, voiceMail, subject });
    setIsScriptModalOpen(true);
    closeModal();
    // Close the Events sidebar when Scripts modal is opened
    setIsEventsSidebarOpen(false);
  };

  const closeScriptModal = () => {
    setIsScriptModalOpen(false);
  };

  const openEventsSidebar = () => {
    setIsEventsSidebarOpen(true);
    // Close the Scripts modal when Events sidebar is opened
    setIsModalOpen(false);
  };

  const closeEventsSidebar = () => {
    setIsEventsSidebarOpen(false);
  };

  const handleLogout = async () => {
    // Remove items from localStorage
    await storeLogOut(loggedInUser);
    localStorage.removeItem("loggedInUser");
    localStorage.removeItem("isAdmin");

    // Navigate to the login page
    window.location.href = "/";
  };

  const events = filterEvents();

  return (
    <nav className="bg-gray-100 p-4 border-solid border-b-2 border-zinc-500 relative">
      <div className="container mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex gap-x-4 items-center">
            <Link to="/dashboard">
              <div className="text-black font-bold text-xl">Home</div>
              {/* Message */}
            </Link>
            <div>
              {events.length > 0 && (
                <div className="flex gap-x-2">
                  {events.slice(0, 3).map((event, index) => (
                    <p
                      key={index}
                      className="text-zinc-600 border border-zinc-300 p-2"
                    >
                      {event.name}
                    </p>
                  ))}
                  {events.length > 3 && (
                    <button onClick={openEventsSidebar}>
                      <p className="text-zinc-600 font-bold underline">
                        +{events.length - 3} other{events.length > 4 && `s`}
                      </p>
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>

          <ul className="flex space-x-4">
            <li className="text-black cursor-pointer p-2">
              <Link to="/reports">Reports</Link>
            </li>
            <li className="text-black cursor-pointer p-2" onClick={openModal}>
              Scripts
            </li>
            <li
              className="text-black cursor-pointer p-2"
              onClick={openEventsSidebar} // New button for Events
            >
              Events
            </li>
            <li
              className="font-black cursor-pointer p-2 bg-red-500 text-white rounded-md"
              onClick={handleLogout}
            >
              Log out
            </li>
          </ul>
        </div>
      </div>

      {/* Script Modal */}
      {isScriptModalOpen && (
        <ScriptModal
          message={selectedScript.message}
          voicemail={selectedScript.voiceMail}
          subject={selectedScript.subject}
          closeScriptModal={closeScriptModal}
        />
      )}

      {/* Events Sidebar */}
      {isEventsSidebarOpen && (
        <div className="fixed inset-y-0 left-0 flex items-start justify-start z-50 shadow-md">
          <div className="modal-container bg-white h-screen w-96">
            <button
              className="modal-close absolute top-0 right-0 p-4"
              onClick={closeEventsSidebar}
            >
              X
            </button>
            <div className="modal-content p-4">
              <p className="text-lg font-bold mb-2">Events</p>
              {/* Use the EventCard component for each event */}
              <Events events={events} />
            </div>
          </div>
        </div>
      )}

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-y-0 left-0 flex items-start justify-start z-50 shadow-md">
          <div className="modal-container bg-white h-screen">
            <button
              className="modal-close absolute top-0 right-0 p-4"
              onClick={closeModal}
            >
              X
            </button>
            <div className="modal-content p-4">
              <p className="text-lg font-bold mb-2">Phone Scripts</p>
              <ul className="space-y-2">
                <li>
                  <button
                    className="text-blue-600 hover:underline"
                    onClick={() =>
                      openScriptModal({
                        message: initialCall().message,
                        voiceMail: initialCall().voiceMail,
                        subject: initialCall().subject,
                      })
                    }
                  >
                    Explain Younified
                  </button>
                </li>

                <li>
                  <button
                    className="text-blue-600 hover:underline"
                    onClick={() =>
                      openScriptModal({
                        message: interimCall().message,
                        voiceMail: interimCall().voiceMail,
                        subject: interimCall().subject,
                      })
                    }
                  >
                    Interim Wait Call
                  </button>
                </li>

                <li>
                  <button
                    className="text-blue-600 hover:underline"
                    onClick={() =>
                      openScriptModal({
                        message: confirmDemo({ unionData: null }).message,
                        voiceMail: confirmDemo({ unionData: null }).voiceMail,
                        subject: confirmDemo({ unionData: null }).subject,
                      })
                    }
                  >
                    Confirm Demo Call
                  </button>
                </li>

                <li>
                  <button
                    className="text-blue-600 hover:underline"
                    onClick={() =>
                      openScriptModal({
                        message: wakeUpCall().message,
                        voiceMail: wakeUpCall().voiceMail,
                        subject: wakeUpCall().subject,
                      })
                    }
                  >
                    Wake Up Call
                  </button>
                </li>
              </ul>
              {/* ... (existing code) ... */}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
