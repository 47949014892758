import React from "react";

const Button = ({ children, color, action }) => {
  let buttonClasses = "px-4 py-2 rounded-md";

  switch (color) {
    case "green":
      buttonClasses += " bg-green-500 text-white hover:bg-green-600";
      break;
    case "blue":
      buttonClasses += " bg-blue-500 text-white hover:bg-blue-600";
      break;
    case "red":
      buttonClasses += " bg-red-500 text-white hover:bg-red-600";
      break;
    case "muted":
      buttonClasses += " bg-gray-300 text-gray-600 hover:bg-gray-400";
      break;
    default:
      buttonClasses += " bg-gray-500 text-white hover:bg-gray-600";
      break;
  }

  return (
    <button className={buttonClasses} onClick={action}>
      {children}
    </button>
  );
};

export const GreenButton = ({ children, action }) => (
  <Button color="green" action={action}>
    {children}
  </Button>
);

export const BlueButton = ({ children, action }) => (
  <Button color="blue" action={action}>
    {children}
  </Button>
);

export const RedButton = ({ children, action }) => (
  <Button color="red" action={action}>
    {children}
  </Button>
);

export const MutedButton = ({ children, action }) => (
  <Button color="muted" action={action}>
    {children}
  </Button>
);
