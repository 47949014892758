import axios from 'axios';

const deleteAllTasks = async ({ data }) => {
  try {
    const params = {
      localNumber: data.localNumber,
      union: data.union,
    };

    const response = await axios.get(
      "https://unionapi.netlify.app/.netlify/functions/api/tasks",
      { params },
    );

    if (response.data.length > 0) {
      const taskIdsToDelete = response.data.map((task) => task._id);

      await Promise.all(
        taskIdsToDelete.map((taskId) =>
          axios.delete(
            `https://unionapi.netlify.app/.netlify/functions/api/tasks/${taskId}`,
          ),
        ),
      );

      console.log("Tasks deleted successfully");
    } else {
      console.log("No matching tasks found");
    }
  } catch (error) {
    console.error("Error deleting tasks:", error);
  }
};

export async function SendToDataEntry({ data, reason, note }) {
  try {
    // Call deleteAllTasks function
    await deleteAllTasks({ data });

    const updatedData = {
      ...data,
      returnedReason: reason,
      returnedDate: new Date().toISOString().split('T')[0], // Get today's date without time
      clientType:"Returned"
    };

    if (note) {
      updatedData.returnedNote = note;
    }

    await axios.put(`https://unionapi.netlify.app/.netlify/functions/api/unions/${data._id}`, updatedData);

    console.log("Data updated successfully");
  } catch (error) {
    console.error("Error updating data:", error);
  }
}
