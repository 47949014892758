import React, { useState } from "react";
import Modal from "./Modal";
import axios from "axios";
import { loggedInUser } from '../../helpers/localStorage';

export default function BulkEmailModal({ closeModal, selectedEmails, clearSelectedLeads }) {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  // Assuming loggedInUser() returns an object with a name property for the repName
  const repName = loggedInUser();

  const sendEmails = async () => {
    setIsSending(true);
    try {
      const emailPromises = selectedEmails.map(email => {
        return axios.post('https://unionapi.netlify.app/.netlify/functions/api/send-email', {
          to: email,
          subject,
          text: message,
          repName, // Pass the repName from loggedInUser
        });
      });
  
      await Promise.all(emailPromises);
      alert("Emails sent successfully!");

      // Deselect leads after a successful send
      clearSelectedLeads();
    } catch (error) {
      console.error("Error sending emails:", error);
      alert("Failed to send emails.");
    } finally {
      setIsSending(false);
      closeModal();
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <h2 className="text-xl font-bold">Send Bulk Email</h2>
      <p className="mb-4 text-gray-700">{`You are about to send emails to ${selectedEmails.length} recipients.`}</p>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Subject</label>
        <input
          type="text"
          className="block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Enter email subject"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Message</label>
        <textarea
          className="block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter email message"
          rows="6"
        />
      </div>
      <div className="flex gap-x-2 justify-end">
        <button
          onClick={closeModal}
          className="bg-red-500 text-white py-2 px-4 rounded-lg"
        >
          Cancel
        </button>
        <button
          onClick={sendEmails}
          className="bg-green-500 text-white py-2 px-4 rounded-lg"
          disabled={isSending}
        >
          {isSending ? "Sending..." : "Send Emails"}
        </button>
      </div>
    </Modal>
  );
}
