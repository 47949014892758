export function calculateTimeDifference(startTime, endTime) {
  const [startHour, startMinutes, startPeriod] = startTime.match(/\d+|\w+/g);
  const [endHour, endMinutes, endPeriod] = endTime.match(/\d+|\w+/g);

  let startHour24 = parseInt(startHour, 10);
  let endHour24 = parseInt(endHour, 10);

  if (startPeriod === "PM" && startHour24 !== 12) {
    startHour24 += 12;
  }

  if (endPeriod === "PM" && endHour24 !== 12) {
    endHour24 += 12;
  }

  const startDate = new Date();
  startDate.setHours(startHour24, parseInt(startMinutes, 10), 0);

  const endDate = new Date();
  endDate.setHours(endHour24, parseInt(endMinutes, 10), 0);

  const timeDifference = endDate - startDate;
  const totalMinutes = Math.floor(timeDifference / (1000 * 60));

  return `${totalMinutes} minutes`;
}

export function formatTimeToLocalTime(startTime, endTime, timeZone) {
  // Parse input times in '9:00 AM' format to Date objects using Canada/Eastern time zone
  const parseTime = (time) => {
    const [hours, minutes, period] = time.match(/(\d+):(\d+) (AM|PM)/).slice(1);
    const easternTime = new Date(
      2023,
      0,
      1,
      parseInt(hours, 10) + (period === "PM" ? 12 : 0),
      parseInt(minutes, 10),
      0,
    );
    return easternTime;
  };

  const easternStartTime = parseTime(startTime);
  const easternEndTime = parseTime(endTime);

  // Check if formattedStartTime is greater than formattedEndTime
  const options = {
    timeZone,
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  };
  const formatter = new Intl.DateTimeFormat("en-US", options);

  let formattedStartTime = formatter.format(easternStartTime);
  let formattedEndTime = formatter.format(easternEndTime);

  // Convert formatted times to 24-hour format for easy comparison
  const startTime24 =
    easternStartTime.getHours() * 60 + easternStartTime.getMinutes();
  const endTime24 =
    easternEndTime.getHours() * 60 + easternEndTime.getMinutes();

  if (startTime24 > endTime24) {
    // If start time is greater than end time, adjust end time to PM
    formattedEndTime = formatter.format(
      new Date(easternEndTime.getTime() + 12 * 60 * 60 * 1000),
    );
  }

  return { formattedStartTime, formattedEndTime };
}

export function getCurrentTimeInTimeZone(timezone) {
  const options = {
    timeZone: timezone,
    hour12: true, // Use 12-hour format with AM/PM
    hour: "numeric",
    minute: "numeric",
  };

  const formatter = new Intl.DateTimeFormat([], options);
  const currentTime = formatter.format(new Date());

  const easternTimezoneOffset = new Date()
    .toLocaleString("en-US", { timeZone: "America/New_York" })
    .split(", ")[1];
  const timezoneOffset = new Date()
    .toLocaleString("en-US", { timeZone: timezone })
    .split(", ")[1];

  let offsetText = "";
  if (easternTimezoneOffset === timezoneOffset) {
    offsetText = "(Same as EST)";
  } else {
    const offsetDifference =
      parseInt(timezoneOffset, 10) - parseInt(easternTimezoneOffset, 10);
    offsetText = `(${Math.abs(offsetDifference)} hour${Math.abs(offsetDifference) !== 1 ? "s" : ""} ${
      offsetDifference >= 0 ? "ahead" : "behind"
    } of EST)`;
  }

  return `${currentTime} ${offsetText}`;
}
