import axios from "axios";
import { nextBusinessDay, subtractBusinessDays } from "../formatDate";
import addHistory from "../historyHelper";
import { appointmentConfirmation } from "../scriptTemplates/emailTemplates";
import { confirmDemo } from "../scriptTemplates/phoneTemplates";
import { formatSpelledDate } from "../formatDate";
import { v4 as uuidv4 } from "uuid";

export async function reschedule({
  unionName,
  data,
  localNumber,
  startTime,
  endTime,
  notes,
  scheduleDate,
  refreshSuspectList,
}) {
  try {
    // Construct the URL to search for tasks with the same unionName and localNumber
    const taskSearchUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${unionName}&localNumber=${localNumber}&transferable=false`;

    // Make an HTTP GET request to search for tasks
    const taskSearchResponse = await axios.get(taskSearchUrl);

    if (taskSearchResponse.status === 200) {
      // Add a history record
      addHistory({
        id: data._id,
        note: "Rescheduled Demo",
        reason: `${formatSpelledDate(scheduleDate)}, ${startTime}-${endTime}`,
      });
      // Assuming there are at least two matching tasks
      const taskData = taskSearchResponse.data;

      const today = new Date();
      const scheduleDateObj = new Date(scheduleDate);
      // Create the updated task objects
      const updatedTasks = [
        {
          //if scheduledDate before new date then do this instead for date: subtractBusinessDays(new Date(scheduleDate), 1),
          date:
            scheduleDateObj < today
              ? subtractBusinessDays(scheduleDateObj, 2)
              : new Date(),

          completed: false,
          script: appointmentConfirmation({
            unionData: data,
            appointmentDate: scheduleDate,
            startTime: startTime,
            endTime: endTime,
          }),
          description: `Confirm they are available on ${formatSpelledDate(scheduleDate)}`,
        },
        {
          date: subtractBusinessDays(new Date(scheduleDate), 1),
          completed: false,
          script: confirmDemo({
            unionData: data,
            appointmentDate: scheduleDate,
            startTime: startTime,
            endTime: endTime,
          }),
          description: `Call to confirm they are available on ${formatSpelledDate(scheduleDate)}`,
        },
        {
          title: `Demo with ${data.union} ${data.localNumber}`,
          startTime,
          endTime,
          notes,
          date: scheduleDate,
          completed: false,
        },

        {
          date: scheduleDate,
          completed: false,
        },
      ];

      // Update the first and second tasks
      for (let i = 0; i < 4; i++) {
        const taskUpdateUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks/${taskData[i]._id}`;

        // Make an HTTP PUT request to update the task
        const taskPutResponse = await axios.put(taskUpdateUrl, updatedTasks[i]);

        if (taskPutResponse.status === 200) {
          console.log(
            `Task ${i + 1} PUT request successful:`,
            taskPutResponse.data,
          );
          // Handle the response data if needed
        } else {
          console.error(
            `Task ${i + 1} PUT request failed:`,
            taskPutResponse.status,
            taskPutResponse.data,
          );
          // Handle error cases for the task PUT request
        }
      }

      // Call the refreshSuspectList function here
      refreshSuspectList();
    } else {
      console.error(
        "Task search GET request failed:",
        taskSearchResponse.status,
        taskSearchResponse.data,
      );
      // Handle error cases for the task search GET request
    }
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle any exceptions here
  }
}

export async function noShow(unionName, localNumber, refreshTasks) {
  try {
    // Construct the URL to search for tasks with the same unionName and localNumber
    const taskSearchUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${unionName}&localNumber=${localNumber}`;

    // Make an HTTP GET request to search for tasks
    const taskSearchResponse = await axios.get(taskSearchUrl);

    if (taskSearchResponse.status === 200) {
      let demoTasks = taskSearchResponse.data;
      console.log(demoTasks);

      for (let i = 0; i < demoTasks.length; i++) {
        const taskUpdateUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks/${demoTasks[i]._id}`;
        if (i === 0) {
          // Updating the first task's title
          await axios.put(taskUpdateUrl, {
            title: "Recontact about missed appointment",
            completable: false,
            startTime: null,
            endTime: null,
          });
        } else {
          // Marking all other tasks as completed
          await axios.put(taskUpdateUrl, { completed: true });
        }
      }

      // Refreshing tasks after updates, if applicable
      if (typeof refreshTasks === "function") {
        refreshTasks(unionName, localNumber);
      }
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
}

async function deleteTaskById(taskId) {
  try {
    const taskDeleteUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks/${taskId}`;

    // Make an HTTP DELETE request to delete the task
    const taskDeleteResponse = await axios.delete(taskDeleteUrl);

    if (taskDeleteResponse.status === 200) {
      console.log(`Task DELETE request successful for task ID: ${taskId}`);
    } else {
      console.error(`Task DELETE request failed for task ID: ${taskId}`);
    }
  } catch (error) {
    console.error(
      `An error occurred while deleting task with ID ${taskId}:`,
      error,
    );
  }
}

async function getAllTasksAndDelete(unionName, localNumber) {
  try {
    // Construct the URL to get all tasks with the given parameters
    const taskSearchUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${unionName}&localNumber=${localNumber}`;

    // Make an HTTP GET request to retrieve all tasks
    const taskSearchResponse = await axios.get(taskSearchUrl);

    if (taskSearchResponse.status === 200) {
      // Extract the list of tasks from the response
      const tasks = taskSearchResponse.data;

      // Iterate through the tasks and delete each one by its _id
      for (const task of tasks) {
        // Check if the title includes 'Pre-Election' before deleting
        if (!task.title.includes('Pre-Election')||task.transferable!==true) {
          await deleteTaskById(task._id);
        } else {
          console.log(`Skipping task deletion for "${task.title}"`);
        }
      }
    } else {
      console.error(
        "Task search GET request failed:",
        taskSearchResponse.status,
        taskSearchResponse.data,
      );
      // Handle error cases for the task search GET request
    }
  } catch (error) {
    console.error("An error occurred while deleting tasks:", error);
    // Handle any exceptions here
  }
}


async function getAllTasksAndDeleteExceptThankYou(unionName, localNumber) {
  try {
    // Construct the URL to get all tasks with the given parameters
    const taskSearchUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${unionName}&localNumber=${localNumber}`;

    // Make an HTTP GET request to retrieve all tasks
    const taskSearchResponse = await axios.get(taskSearchUrl);

    if (taskSearchResponse.status === 200) {
      // Extract the list of tasks from the response
      const tasks = taskSearchResponse.data;

      // Iterate through the tasks and delete each one by its _id, unless the title contains 'Send thank you letter' or 'Pre-Election'
      for (const task of tasks) {
        if (!task.title.includes("Send thank you letter") && !task.title.includes("Pre-Election")&&task.transferable!==true) {
          await deleteTaskById(task._id);
        }
      }
    } else {
      console.error(
        "Task search GET request failed:",
        taskSearchResponse.status,
        taskSearchResponse.data,
      );
      // Handle error cases for the task search GET request
    }
  } catch (error) {
    console.error("An error occurred while deleting tasks:", error);
    // Handle any exceptions here
  }
}


async function createFollowUpTask(data) {
  const taskToCreate = {
    union: data.union,
    localNumber: data.localNumber,
    date: nextBusinessDay(3),
    link: data._id,
    completable: false,
    assignedRep: data.assignedRep,
    title: `Follow up after demo with ${data.union} ${data.localNumber}`,
    description: "",
    clientType: "Proposal",
    contactEmail:
      data.primaryContact && data.primaryContact === 1
        ? data.contactEmail || data.alternateContactEmail || data.email
        : data.email || "",
    propertyToUpdate: "callsMade",
  };

  try {
    const response = await axios.post(
      "https://unionapi.netlify.app/.netlify/functions/api/tasks",
      taskToCreate,
    );
    console.log("Task created:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating task:", error);
    throw error; // Re-throw the error if you need to handle it further up in your call stack
  }
}
// CONTINUE HERE!
export async function noContactAfterDemo({
  data,
  reason,
  note,
  nextContact,
  refreshTasks,
}) {
  try {
    // Define the URL for the GET request
    const getUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${encodeURIComponent(data.union)}&localNumber=${encodeURIComponent(data.localNumber)}`;

    // Perform the GET request
    const response = await axios.get(getUrl);

    // Check if there's a task with a title containing 'Follow up'
    const followUpTask = response.data.find((task) =>
      task.title.includes("Follow up"),
    );

    // If such a task exists, update its 'date' property using a PUT request
    if (followUpTask) {
      const putUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks/${followUpTask._id}`;
      await axios.put(putUrl, { ...followUpTask, date: nextContact });
    }

    // Add a history record
    addHistory({
      id: data._id,
      note: note,
      reason: reason,
    });

    // Refresh tasks
    refreshTasks(data.union, data.localNumber);
  } catch (error) {
    console.log("Error:", error);
  }
}

export async function waitAfterDemo({
  data,
  reason,
  nextContact,
  refreshTasks,
}) {
  try {
    // Define the URL for the GET request
    const getUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${encodeURIComponent(data.union)}&localNumber=${encodeURIComponent(data.localNumber)}`;

    // Perform the GET request
    const response = await axios.get(getUrl);

    // Check if there's a task with a title containing 'Follow up'
    const followUpTask = response.data.find((task) =>
      task.title.includes("Follow up"),
    );

    // If such a task exists, update its 'date' property using a PUT request
    if (followUpTask) {
      const putUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks/${followUpTask._id}`;
      await axios.put(putUrl, { ...followUpTask, date: nextContact });
    }

    // Add a history record
    addHistory({
      id: data._id,
      note: "Lead wants to wait after the demo",
      reason: reason,
    });

    // Refresh tasks
    refreshTasks(data.union, data.localNumber);
  } catch (error) {
    console.log("Error:", error);
  }
}

//This function checks if it is demo day for leads in the appointment phase
export async function checkForUpcomingDemos(data) {
  const { union, localNumber } = data;
  const url = `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${union}&localNumber=${localNumber}`;

  try {
    const response = await axios.get(url);
    const tasks = response.data;

    const today = new Date();
    today.setUTCHours(0, 0, 0, 0); // Set hours to midnight UTC time

    for (const task of tasks) {
      if (task.title.includes("Demo with")) {
        const taskDate = new Date(task.date);
        taskDate.setUTCHours(0, 0, 0, 0); // Normalize task date to UTC midnight for fair comparison
        if (taskDate <= today) {
          // Change this to check if the date has passed
          return true;
        }
      }
    }

    return false;
  } catch (error) {
    console.error("Error fetching data:", error);
    return false; // Return false if there's an error in fetching or processing data
  }
}

function getAfterSpace(str) {
  let parts = str.split(" ");
  // Remove the first element and join the rest back into a string
  return parts.slice(1).join(" ");
}

function getBeforeSpace(str) {
  return str.split(" ")[0];
}

export async function moveToProposal({
  unionName,
  localNumber,
  id,
  refreshSuspectList,
  data,
}) {
  try {
    // Step 1: Add history

    // Step 2: Make a PUT request to update the clientType
    await axios.put(
      `https://unionapi.netlify.app/.netlify/functions/api/unions/${id}`,
      { clientType: "Proposal" },
    );

    // Step 3: Call the `getAllTasksAndDelete` function
    await getAllTasksAndDeleteExceptThankYou(unionName, localNumber); // Use await to ensure it's completed before continuing

    await createFollowUpTask(data);

    addHistory({
      id: id,
      note: `Activation set to proposal`,
      reason: ``,
    });
    // Step 4: Call the refreshSuspectList function
    refreshSuspectList();
  } catch (error) {
    // Handle errors here
    console.error("Error occurred:", error);
    // You can choose to rethrow the error or take any other necessary action.
  }
}

export async function moveToClient({
  unionName,
  localNumber,
  id,
  refreshSuspectList,
  data,
}) {
  const newExecutives = [
    {
      _id: uuidv4(),
      firstName: data.contactName
        ? getBeforeSpace(data.contactName)
        : "Missing First Name",
      lastName: data.contactName
        ? getAfterSpace(data.contactName)
        : "Missing Last Name",
      jobTitle: data.contactJobTitle || "",
      cellPhone: data.contactNumber1 || "",
      officePhone: data.contactNumber2 || "",
      email: data.contactEmail || data.email,
      gender: data.contactGender || "",
      dateOfBirth: data.contactBirthDay || "",
      isPrimaryContact: data.primaryContact
        ? data.primaryContact === 1
          ? true
          : false
        : true,
    },
  ];

  // Check for alternateContactName and add to newExecutives if it exists
  if (data.alternateContactName) {
    newExecutives.push({
      _id: uuidv4(),
      firstName: data.alternateContactName
        ? getBeforeSpace(data.alternateContactName)
        : "Missing First Name",
      lastName: data.alternateContactName
        ? getAfterSpace(data.alternateContactName)
        : "Missing Last Name",
      jobTitle: data.alternateContactJobTitle || "",
      cellPhone: data.alternateContactNumber1 || "",
      officePhone: data.alternateContactNumber2 || "",
      email: data.alternateContactEmail || data.email,
      gender: data.alternateContactGender || "",
      dateOfBirth: data.alternateContactBirthDay || "",
      isPrimaryContact: data.primaryContact === 2 ? true : false,
    });
  }

  try {
    // Step 1: Add history

    // Step 2: Make a PUT request to update the clientType
    await axios.put(
      `https://unionapi.netlify.app/.netlify/functions/api/unions/${id}`,
      {
        clientType: "Client",
      },
    );

    const newClientID=uuidv4();

    await axios.post(
      `https://callcalendarapi.netlify.app/.netlify/functions/api/clients`,
      {
        _id:newClientID,
        union: data.union,
        localNumber: data.localNumber,
        officePhoneNumber: data.officePhoneNumber || "",
        website: data.website || "",
        faxNumber: data.faxNumber || "",
        email: data.email || "",
        memberSize: data.memberSize || "",
        province: data.province || "",
        postal: data.postal || "",
        address: data.address || "",
        city: data.city || "",
        revenue: data.revenue || "",
        electionCycle: data.electionCycle || "",
        electionDate: data.electionDate || "",
        bargainingUnits: data.bargainingUnits || "",
        timeZone: data.timeZone || "",
        notes: data.notes || "",
        clientType: "Client",
        meetingFrequency: "weekly",
        executives: newExecutives,
      },
      {
        headers: {
          "X-API-Key": process.env.REACT_APP_CALL_CALENDAR_API_KEY,
        },
      },
    );
    await moveTasksToCalendar(data, newClientID);
    // Step 3: Call the `getAllTasksAndDelete` function
    await getAllTasksAndDelete(unionName, localNumber); // Use await to ensure it's completed before continuing

    addHistory({
      id: id,
      note: `Proposal set to client`,
      reason: ``,
    });
    // Step 4: Call the refreshSuspectList function
    refreshSuspectList();
  } catch (error) {
    // Handle errors here
    console.error("Error occurred:", error);
    // You can choose to rethrow the error or take any other necessary action.
  }
}


async function moveTasksToCalendar(data, newClientID) {
  try {
    const response = await axios.get(`https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${data.union}&localNumber=${data.localNumber}&transferable=true&completed=false`);
    const tasksToTransfer = response.data; // Assuming the response contains the tasks
    
    if (tasksToTransfer.length > 0) {
      const updatedTasks = tasksToTransfer.map(task => ({
        ...task,
        link: newClientID // Set the link property to newClientID for each task
      }));
      
      await axios.post(
        `https://callcalendarapi.netlify.app/.netlify/functions/api/tasks`,
        updatedTasks,
        {
          headers: {
            "X-API-Key": process.env.REACT_APP_CALL_CALENDAR_API_KEY,
          },
        },
      );
      console.log("Tasks transferred successfully.");
    } else {
      console.log("No tasks to transfer.");
    }
  } catch (error) {
    console.error("Error occurred:", error);
    // Handle errors here
  }
}



