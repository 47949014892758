import React, { useState } from "react";
import "../../helpers/scriptTemplates/phoneScript.css";

export default function ScriptModal({
  message,
  voicemail,
  subject,
  closeScriptModal,
}) {
  const [activeTab, setActiveTab] = useState("phone");

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
      <div className="bg-white p-4 rounded-lg shadow-lg z-50 w-3/4 h-5/6 flex flex-col">
        {voicemail && (
          <div className="sticky top-0 bg-gray-200 p-2 mb-2">
            <button
              className={`px-4 py-2 mr-4 focus:outline-none ${
                activeTab === "phone" ? "bg-blue-500 text-white" : "bg-gray-300"
              }`}
              onClick={() => setActiveTab("phone")}
            >
              Phone Script
            </button>
            <button
              className={`px-4 py-2 focus:outline-none ${
                activeTab === "voicemail"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300"
              }`}
              onClick={() => setActiveTab("voicemail")}
            >
              Voice Mail Script
            </button>
          </div>
        )}

        <div className="flex-1 overflow-y-auto">
          <p className="text-xl font-bold">{subject}</p>
          {activeTab === "phone" ? (
            <div
              className="text-xl message-container"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          ) : (
            <div
              className="text-xl voicemail-container"
              dangerouslySetInnerHTML={{ __html: voicemail }}
            />
          )}
        </div>

        <button
          className="px-4 py-2 max-w-[100px] bg-red-500 text-white rounded hover:bg-red-600 mt-4"
          onClick={closeScriptModal}
        >
          Close
        </button>
      </div>
    </div>
  );
}
