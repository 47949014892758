import React, { useState } from "react";
import Modal from "./Modal";
import Calendar from "../Calendar";
// import axios from 'axios';
// import { loggedInUser } from '../../helpers/localStorage';
import { formatSpelledDate } from "../../helpers/formatDate";
import { RedButton, GreenButton } from "../buttons/button";
import { timeOptions } from "../../data/times";
import TimeDropdown from "../dropdowns/TimeDropdown"; // Import the TimeDropdown component
import { yesFunction } from "../../helpers/strategies/infopak";

export default function YesModal({
  closeModal,
  data,
  refreshSuspectList,
  tasks,
}) {
  const [showCalendar, setShowCalendar] = useState(true);
  const [isFullModal, setIsFullModal] = useState(true);
  const [scheduleDate, setScheduleDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [notes, setNotes] = useState("");

  const handleShowCalendar = () => {
    setShowCalendar(true);
    setIsFullModal(true);
  };

  const handleCloseCalendar = () => {
    setShowCalendar(false);
    setIsFullModal(false);
  };

  const confirmSchedule = (
    union,
    localNumber,
    id,
    appointmentDate,
    startTime,
    endTime,
    notes,
  ) => {
    closeModal();
    yesFunction(
      union,
      localNumber,
      id,
      appointmentDate,
      startTime,
      endTime,
      notes,
      refreshSuspectList,
      data,
    ); // Leaving note blank as an empty string
  };

  return (
    <Modal closeModal={closeModal} fullModal={isFullModal}>
      <h2 className="text-xl font-bold">Schedule Appointment (Eastern Time)</h2>
      {showCalendar ? (
        <Calendar
          tasks={tasks}
          onCloseCalendar={handleCloseCalendar}
          setScheduleDate={setScheduleDate}
        />
      ) : (
        <div>
          <div className="flex gap-x-2 mt-4">
            <TimeDropdown
              label="Start Time"
              placeholder="Select Start Time"
              options={timeOptions}
              selectedValue={startTime}
              onChange={(value) => setStartTime(value)}
            />
            <TimeDropdown
              label="End Time"
              placeholder="Select End Time"
              options={timeOptions}
              selectedValue={endTime}
              onChange={(value) => setEndTime(value)}
            />
          </div>
          <div className="mt-4">
            <label
              htmlFor="notes"
              className="block text-sm font-medium text-gray-700"
            >
              Notes
            </label>
            <textarea
              id="notes"
              name="notes"
              rows="3"
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
            ></textarea>
          </div>
          {scheduleDate ? (
            <div className="gap-x-2 flex items-center mt-4">
              <p>
                Call back will be scheduled to:{" "}
                <span className="font-bold">
                  {formatSpelledDate(scheduleDate)}
                </span>
              </p>
              <button
                className="bg-red-500 p-2 rounded-lg text-white font-bold"
                onClick={handleShowCalendar}
              >
                Change
              </button>
            </div>
          ) : (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleShowCalendar}
            >
              Select Date
            </button>
          )}
        </div>
      )}
      {scheduleDate && startTime && endTime && (
        <div className="flex gap-x-2 mt-16 justify-end">
          <RedButton action={closeModal}>Cancel</RedButton>
          <GreenButton
            action={() =>
              confirmSchedule(
                data.union,
                data.localNumber,
                data._id,
                scheduleDate,
                startTime,
                endTime,
                notes,
              )
            }
          >
            Schedule Appointment
          </GreenButton>
        </div>
      )}
    </Modal>
  );
}
