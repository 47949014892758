export async function logoutUser() {
  // Assuming that localStorage operations are not asynchronous,
  // we don't need to use async/await for localStorage methods.

  localStorage.removeItem("loggedInUser");
  localStorage.removeItem("isAdmin");

  // If you want to wait for the removal before redirecting,
  // you can use a small delay with setTimeout.

  await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust the delay as needed

  window.location.href = "/";
}
