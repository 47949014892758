import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import users from "../data/users.json";
import { createHistoryRecord } from "../helpers/userActionHistory";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const hostname = window.location.hostname;
  const handleLogin = () => {
    const user = users.find((userObj) => userObj.username === username);

    if (
      user &&
      (user.password === password || hostname.includes("localhost"))
    ) {
      console.log("Logged in successfully!");
      createHistoryRecord(username);

      if (username === "admin" || username === "fred") {
        localStorage.setItem("isAdmin", true);
      }
      // Store the logged-in user's username in local storage
      localStorage.setItem("loggedInUser", username);

      navigate("/dashboard"); // Use navigate directly without .push()
      // Handle successful login (e.g., redirect or update state)
    } else {
      setError("Invalid username or password.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-xl shadow-xl w-96 space-y-4">
        <h1 className="text-2xl font-bold text-center">CRM Login</h1>

        <div className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Username
            </label>
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              id="username"
              name="username"
              className="mt-1 w-full p-2 border rounded-md"
              placeholder="Enter your username"
            />
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              name="password"
              className="mt-1 w-full p-2 border rounded-md"
              placeholder="Enter your password"
            />
          </div>
        </div>

        <div className="flex justify-between items-center">
          {error && <p className="text-red-500">{error}</p>}
          <button
            onClick={handleLogin}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
