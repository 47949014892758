import React, { useEffect, useState, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import * as XLSX from 'xlsx';

export default function YTDReportContent({
  data,
  salesReps,
  setSelectedRep,
  selectedRep,
  isAdmin,
}) {
  const currentYear = new Date().getFullYear().toString();
  const currentMonth = new Date().getMonth() + 1;

  const monthLabels = useMemo(() => [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ].slice(0, currentMonth), [currentMonth]);

  const [chartData, setChartData] = useState({
    labels: monthLabels,
    datasets: [],
  });

  useEffect(() => {
    const filteredData = data.filter((entry) =>
      entry.date.startsWith(currentYear)
    );
    const monthlyData = Object.fromEntries(
      monthLabels.map((_, i) => [
        `${i + 1}`.padStart(2, "0"),
        { callsMade: 0, emailsSent: 0, packagesSent: 0, demosBooked: 0 },
      ])
    );

    filteredData.forEach((day) => {
      const month = day.date.split("-")[1];
      if (monthlyData.hasOwnProperty(month)) {
        day.users.forEach((user) => {
          if (user.name.toLowerCase() === selectedRep.toLowerCase()) {
            if (user.callsMade) {
              monthlyData[month].callsMade += user.callsMade;
            }
            if (user.emailsSent) {
              monthlyData[month].emailsSent += user.emailsSent;
            }
            if (user.packagesSent) {
              monthlyData[month].packagesSent += user.packagesSent;
            }
            if (user.demosBooked) {
              monthlyData[month].demosBooked += user.demosBooked;
            }
          }
        });
      }
    });

    const callsMade = [];
    const emailsSent = [];
    const packagesSent = [];
    const demosBooked = [];
    const totalKPIs = [];

    Object.values(monthlyData).forEach((month) => {
      callsMade.push(month.callsMade);
      emailsSent.push(month.emailsSent);
      packagesSent.push(month.packagesSent);
      demosBooked.push(month.demosBooked);
      totalKPIs.push(
        month.callsMade +
          month.emailsSent +
          month.demosBooked +
          month.packagesSent
      );
    });

    const newChartData = {
      labels: monthLabels,
      datasets: [
        {
          label: "Calls Made",
          data: callsMade,
          backgroundColor: "rgb(255, 99, 132)",
        },
        {
          label: "Emails Sent",
          data: emailsSent,
          backgroundColor: "rgb(54, 162, 235)",
        },
        {
          label: "Packages Sent",
          data: packagesSent,
          backgroundColor: "rgb(255, 206, 86)",
        },
        {
          label: "Demos Booked",
          data: demosBooked,
          backgroundColor: "rgb(75, 192, 192)",
        },
        {
          label: "Total Monthly KPI",
          data: totalKPIs,
          backgroundColor: "red",
        },
      ],
    };

    setChartData(newChartData);
  }, [selectedRep, data, currentYear, monthLabels]);

  const downloadExcel = () => {
    const wsData = [
      [`${selectedRep}'s YTD Sales Report for ${currentYear}`],
      ["Month", "Calls Made", "Emails Sent", "Packages Sent", "Demos Booked", "Total KPI"]
    ];

    monthLabels.forEach((month, index) => {
      const row = [
        month,
        chartData.datasets[0].data[index],
        chartData.datasets[1].data[index],
        chartData.datasets[2].data[index],
        chartData.datasets[3].data[index],
        chartData.datasets[4].data[index]
      ];
      wsData.push(row);
    });

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "YTD Report");

    XLSX.writeFile(
      wb,
      `${selectedRep}_YTD_Report_${currentYear}.xlsx`
    );
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {isAdmin && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Sales Representative:
            </label>
            <select
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={selectedRep}
              onChange={(e) => setSelectedRep(e.target.value)}
            >
              {salesReps.map((rep, index) => (
                <option key={index} value={rep}>
                  {rep}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="flex items-end justify-end col-span-1">
          <button
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md"
            onClick={downloadExcel}
          >
            Download Excel
          </button>
        </div>
      </div>
      <Bar data={chartData} options={options} />
    </div>
  );
}
