import React, { useState, useEffect } from "react";
import { formatDateWithTime } from "../../helpers/formatDate";

export default function LeadPageHistory({ data }) {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const sortedData = [...data.history].sort(
      (a, b) => new Date(b.date) - new Date(a.date),
    );
    const filtered = selectedMonthYear
      ? sortedData.filter((item) => {
          const date = new Date(item.date);
          const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
          return monthYear === selectedMonthYear;
        })
      : sortedData;
    setFilteredData(filtered);
    setCurrentPage(1);
  }, [selectedMonthYear, data.history]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentPageData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const maxPageNumVisible = 5;
  let visiblePages = [];
  if (totalPages <= maxPageNumVisible) {
    visiblePages = Array.from({ length: totalPages }, (_, i) => i + 1);
  } else {
    const pagesBeforeCurrentPage = Math.floor(maxPageNumVisible / 2);
    let startPage = currentPage - pagesBeforeCurrentPage;
    let endPage = currentPage + pagesBeforeCurrentPage;

    if (startPage < 1) {
      startPage = 1;
      endPage = maxPageNumVisible;
    }
    if (endPage > totalPages) {
      startPage = totalPages - (maxPageNumVisible - 1);
      endPage = totalPages;
    }
    visiblePages = Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i,
    );
    if (startPage > 1) {
      visiblePages = [1, "...", ...visiblePages];
    }
    if (endPage < totalPages) {
      visiblePages = [...visiblePages, "...", totalPages];
    }
  }

  return (
    <div className="bg-white px-4 py-2">
      <p className="font-bold text-xl my-2">History</p>
      <select
        className="mb-4 p-2 border border-gray-300 rounded"
        onChange={(e) => setSelectedMonthYear(e.target.value)}
        value={selectedMonthYear}
      >
        <option value="">All Dates</option>
        {[
          ...new Set(
            data.history.map((item) => {
              const date = new Date(item.date);
              return `${date.getMonth() + 1}/${date.getFullYear()}`;
            }),
          ),
        ]
          .sort()
          .map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
      </select>
      <div className="flex flex-col gap-y-2">
        {currentPageData.map((item) => (
          <div key={item._id} className="flex gap-x-16 mb-2 p-2 border-b">
            <div className="w-1/3">
              <p>{item.note}</p>
            </div>
            <div className="w-1/3">
              <p>{item.reason}</p>
            </div>
            <div className="w-1/3">
              <p>{formatDateWithTime(item.date)}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex gap-x-2 justify-center mt-4">
        <button
          className={`px-3 py-1 border rounded ${currentPage === 1 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-100"}`}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {visiblePages.map((page) =>
          page === "..." ? (
            <span className="px-3 py-1">...</span>
          ) : (
            <button
              key={page}
              className={`px-3 py-1 border rounded ${currentPage === page ? "bg-blue-500 text-white" : "hover:bg-gray-100"} ${currentPage !== page && "hover:text-blue-500"}`}
              onClick={() => setCurrentPage(page)}
            >
              {page}
            </button>
          ),
        )}
        <button
          className={`px-3 py-1 border rounded ${currentPage === totalPages ? "cursor-not-allowed opacity-50" : "hover:bg-gray-100"}`}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}
