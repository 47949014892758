import React from "react";

export default function TaskCompleteModal({
  confirmCompleteTask,
  taskNotCompleted,
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
      <div className="bg-white p-4 rounded-lg shadow-lg z-50">
        <p className="mb-4">Has this task been completed?</p>
        <div className="flex justify-end">
          <button
            className="mr-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            onClick={() => confirmCompleteTask("emailsSent")}
          >
            Yes
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={taskNotCompleted}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}
