import React from "react";

export default function Modal({ children, closeModal, fullModal }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
      <div
        className={`bg-white p-6 rounded-lg shadow-lg ${fullModal ? "w-full mx-4" : "w-3/4 md:w-1/2 lg:w-2/3 xl:w-1/2"} overflow-y-auto relative`}
      >
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 p-2 text-gray-600 hover:text-gray-800"
        >
          <span className="text-3xl">&times;</span>
        </button>

        {children}
      </div>
    </div>
  );
}
