import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  startOfWeek,
} from "date-fns";
import "../css/calendar.css";

export default function Calendar({
  onCloseCalendar,
  setScheduleDate,
  hideFunctions,
}) {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    // Fetch tasks from the API
    axios
      .get("https://unionapi.netlify.app/.netlify/functions/api/tasks")
      .then((response) => {
        // Filter tasks with title containing "Demo"
        const demoTasks = response.data.filter(
          (task) => task.title && task.title.includes("Demo"),
        );
        setTasks(demoTasks);
      })
      .catch((error) => {
        console.error("Error fetching tasks:", error);
      });
  }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount

  const startOfMonthDate = startOfMonth(currentMonth);
  const startOfWeekDate = startOfWeek(startOfMonthDate);

  const daysInMonth = eachDayOfInterval({
    start: startOfWeekDate,
    end: endOfMonth(currentMonth),
  });

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const handlePrevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleDayClick = (date) => {
    setSelectedDate(date);
  };

  const handleSelectDate = () => {
    setScheduleDate(selectedDate);
    onCloseCalendar();
  };

  return (
    <div className="calendar mt-2">
      <div className="calendar-header flex justify-between items-center bg-blue-500 text-white p-2 rounded-t-lg">
        <button onClick={handlePrevMonth}>Previous Month</button>
        <h2 className="text-2xl font-semibold">
          {format(currentMonth, "MMMM yyyy")}
        </h2>
        <button onClick={handleNextMonth}>Next Month</button>
      </div>
      <table className="calendar-table w-full">
        <thead className=" border border-gray-300">
          <tr>
            <th className="p-2">Sun</th>
            <th className="p-2">Mon</th>
            <th className="p-2">Tue</th>
            <th className="p-2">Wed</th>
            <th className="p-2">Thu</th>
            <th className="p-2">Fri</th>
            <th className="p-2">Sat</th>
          </tr>
        </thead>
        <tbody>
          {daysInMonth.map((day, index) =>
            index % 7 === 0 ? (
              <tr key={index}>{renderDaysForRow(daysInMonth, index)}</tr>
            ) : null,
          )}
        </tbody>
      </table>

      {hideFunctions === true ? (
        <></>
      ) : (
        <div className="flex justify-end gap-x-8">
          <button
            className="bg-zinc-500 text-white px-2 py-1 rounded mt-4"
            onClick={onCloseCalendar}
          >
            Close Calendar
          </button>

          <button
            className="bg-green-500 text-white px-2 py-1 rounded mt-4"
            onClick={handleSelectDate}
          >
            SelectDate
          </button>
        </div>
      )}
    </div>
  );

  function renderDaysForRow(days, startIndex) {
    // Calculate the width for each cell
    const cellWidth = `${100 / 7}%`; // Distribute equally across 7 days

    const today = new Date(); // Get the current date

    return days.slice(startIndex, startIndex + 7).map((date, index) => {
      const isCurrentDay =
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();

      const cellStyles = {
        width: cellWidth,
        height: "100px",
        border: "1px solid #cccddd",
        backgroundColor:
          selectedDate && selectedDate.getTime() === date.getTime()
            ? "lightblue"
            : "transparent",
      };

      return (
        <td
          key={index}
          className={`p-2 align-top ${isCurrentDay ? "circle-number" : ""}`}
          style={cellStyles}
          onClick={() => handleDayClick(date)}
        >
          {format(date, "d")}
          {isCurrentDay && ` (Today)`}
          {/* Render tasks for this date */}
          {renderTasksForDate(date)}
        </td>
      );
    });
  }

  function renderTasksForDate(date) {
    const formattedDate = format(date, "yyyy-MM-dd"); // Format the calendar date

    const tasksForDate = tasks.filter((task) => {
      const taskDate = format(new Date(task.date), "yyyy-MM-dd"); // Format the task date
      return taskDate === formattedDate;
    });

    const additionalTasks =
      tasksForDate.length > 2 ? `+${tasksForDate.length - 2} others` : "";

    return (
      <ul>
        {tasksForDate.slice(0, 2).map((task, index) => (
          <li key={index}>
            {task.startTime && (
              <>
                <p>
                  <span className="font-bold">
                    {task.union} {task.localNumber}{" "}
                  </span>
                  {task.timeZone && `(${task.timeZone})`}
                </p>
                <p>
                  {task.startTime &&
                    `${task.startTime} EST ${task.endTime && `- ${task.endTime} EST`}`}
                </p>
              </>
            )}
            <hr className="h-px" />
          </li>
        ))}
        {additionalTasks && <li>{additionalTasks}</li>}
      </ul>
    );
  }
}
