import {
  findLoggedInUser,
  loggedInUser,
  returnUserFullName,
} from "../localStorage";
import { calculateTimeDifference, formatTimeToLocalTime } from "../formatTime";

//const title = findLoggedInUser()?findLoggedInUser().title:'title'
const fullName = returnUserFullName();
const phoneNumber = findLoggedInUser()
  ? findLoggedInUser().phonenumber
  : "number";
const email = findLoggedInUser() ? findLoggedInUser().email : "email";

const repName = (unionData) => {
  if (unionData.primaryContact && unionData.primaryContact === 1) {
    return unionData.contactName || "Union Representative";
  } else {
    return unionData.alternateContactName || "Union Representative";
  }
};

export const infopakLetter = (unionData) => {
  const emailTemplate = {
    subject: "Follow-Up: Sending Younified App Brochure and Description",
    isEmail: true,
    message: `Dear ${repName(unionData)},

I want to thank you for speaking with me today, it was a pleasure getting to know a little about your local. We are thrilled that you are interested in learning more about our Younified application. As promised, we will send you an information package This package includes our brochure, which details the services Younified offers with a brief description. Also, in this package you will find our mouse pad and pen, these are gifts for you in appreciation of the time you took to review the information.

We are confident Younified can address the needs of your local. Should you have any questions please call me anytime ${phoneNumber}, or simply respond to this email. We would love to offer as a well a Zoom demonstration to show you how Younified works and take any questions you have. Thank you again for your attention and I look forward to hearing form you soon.

In Solidarity
`,
  };
  return emailTemplate;
};

export const promotionalEmail = (unionData) => {
  const formatField = (label, value) => value ? `${label}: ${value}` : `${label}: Not available`;

  const emailTemplate = {
    subject: `Infopak for ${unionData?.union || 'Union'} ${unionData?.localNumber || ''}`,
    isEmail: true,
    message: `
      Hi Cristina,

      Here is the infopak info for ${unionData?.union || 'the union'} ${unionData?.localNumber || ''}:

      ${formatField('Province', unionData?.province)}
      ${formatField('City', unionData?.city)}
      ${formatField('Address', unionData?.address)}
      ${formatField('Postal Code', unionData?.postal)}
      ${formatField('Office Phone Number', unionData?.officePhoneNumber)}
      ${formatField('General Email', unionData?.email)}

      ${formatField('Executive Name', unionData?.contactName)}
      ${formatField('Executive Email', unionData?.contactEmail)}
      ${formatField('Executive Job Title', unionData?.contactJobTitle)}
      ${formatField('Executive Number', unionData?.contactNumber1)}
 

      Let me know if you need any additional information.

    `,
  };

  return emailTemplate;
};


export const noLetterNoBudget = (unionData) => {
  const emailTemplate = {
    subject: "Supporting Each Other in the Labour Movement",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I trust this email finds you well. I wanted to reach out to discuss the recent conversation regarding the budget for our services and application. I understand that budget constraints can be challenging, especially in the context of the valuable work carried out by our union community.

        While I acknowledge that financial considerations are crucial, I want to emphasize that our commitment to 𝘴𝘶𝘱𝘱𝘰𝘳𝘵𝘪𝘯𝘨 𝘺𝘰𝘶𝘳 𝘶𝘯𝘪𝘰𝘯 goes beyond monetary constraints. We understand the unique challenges faced by labor unions and the critical role they play in the broader labor movement.

        The labor union community is, indeed, a close-knit and interconnected network. We firmly believe that supporting one another is not solely about the financial aspect but rather about fostering a collaborative environment that strengthens the collective voice of workers. Our mission is to contribute to the well-being and success of unions, ensuring their sustainability in the long run.

        In light of the current budget limitations, we want to assure you that our dedication to assisting your union remains unwavering. We are more than willing to explore alternative solutions or find ways to tailor our services to better align with your financial capacity. Our goal is to ensure that your union can continue its essential work without compromising the quality of the services and support it receives.

        It's essential for all of us in the labor movement to unite and support each other. The challenges we face are shared, and by working together, we can overcome them and contribute to the overall strength and resilience of the labor movement.

        As a suggestion, it might be beneficial to 𝗽𝗿𝗶𝗻𝘁 𝗮𝗻𝗱 𝘀𝗮𝘃𝗲 𝘁𝗵𝗶𝘀 𝗲𝗺𝗮𝗶𝗹 in your union's files. Having this on hand could serve as a reference point for future discussions or when the time is right to reconsider our services. We are always available for further discussions, and you can easily reach out when needed.

        If you have any specific concerns or would like to discuss potential adjustments to our services, please feel free to reach out. We are here to collaborate with you and find solutions that meet your union's needs.

        Thank you for your understanding, and we look forward to continuing our partnership in support of the labor movement.`,
  };
  return emailTemplate;
};

export const noLetterNotInterested = (unionData) => {
  const emailTemplate = {
    subject: "Thank You for Considering Our Services",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I trust this message finds you well. I wanted to express our sincere gratitude for the time and consideration you dedicated to exploring the possibility of our services for your union. It has been a pleasure learning about your organization and its goals.

        After careful consideration, we understand that our services may not align with your current needs or priorities. We respect your decision and appreciate the transparency in communicating your preferences. Your honesty allows us to focus our efforts where they are most needed and ensures that our interactions are respectful of your time and resources.

        Please know that even though our services may not be the right fit at this time, we remain committed to supporting the broader labor movement. Should your union's needs evolve in the future, we would be more than happy to revisit our discussions and explore how our services might better align with your requirements.

        In the meantime, we genuinely wish your union continued success in all its endeavors. If there are ever c͟h͟a͟n͟g͟e͟s͟ in your circumstances or if you have questions about our services in the future, please do not hesitate to reach out. We are here to assist in any way we can.

        As a suggestion, it might be beneficial to 𝘀𝗮𝘃𝗲 𝗮𝗻𝗱 𝗽𝗿𝗶𝗻𝘁 𝘁𝗵𝗶𝘀 𝗲𝗺𝗮𝗶𝗹 for future reference. Keeping a copy on hand could make it easier to reconnect should your union's situation change, or if you wish to explore our services at a later date.

        Thank you once again for considering our services, and we wish you and your union all the best in your important work.`,
  };
  return emailTemplate;
};

export const noLetterNotNeeded = (unionData) => {
  const emailTemplate = {
    subject: "Appreciation for Your Consideration of Our Services",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I hope this message finds you well. I wanted to extend my sincere appreciation for the time and consideration you invested in exploring the potential of our services for your union. Learning about your organization and its objectives has been enlightening.

        After careful consideration, it has come to our understanding that, at present, our services may not align with your current needs or priorities. We respect your decision and value the openness in communicating your preferences. This transparency allows us to focus our efforts where they are most needed and ensures that our interactions are respectful of your time and resources.

        Please be assured that even though our services may not be immediately applicable, our commitment to supporting the broader labor movement remains unwavering. Should your union's needs evolve in the future, we would welcome the opportunity to revisit our discussions and explore how our services might better align with your requirements.

        In the meantime, we sincerely wish your union continued success in all its endeavors. If there are ever 𝘤𝘩𝘢𝘯𝘨𝘦𝘴 𝘪𝘯 𝘺𝘰𝘶𝘳 𝘤𝘪𝘳𝘤𝘶𝘮𝘴𝘵𝘢𝘯𝘤𝘦𝘴 or if you have questions about our services down the road, please do not hesitate to reach out. We are here to assist in any way we can.

        As a suggestion, it might be helpful to 𝘀𝗮𝘃𝗲 𝗮𝗻𝗱 𝗽𝗿𝗶𝗻𝘁 𝘁𝗵𝗶𝘀 𝗲𝗺𝗮𝗶𝗹 for future reference. Having a copy on hand could make it more convenient to reconnect should your union's circumstances change, or if you decide to explore our services at a later date.

        Once again, thank you for considering our services, and we wish you and your union ongoing success in your vital work.`,
  };
  return emailTemplate;
};

export const noLetterReferral = (unionData) => {
  const emailTemplate = {
    subject: "Seeking Your Valuable Network Insights",
    isEmail: true,
    message: `Dear ${repName(unionData)},

        I trust this email finds you well. I wanted to express our gratitude for your time and consideration in evaluating our services for your union. While we understand that our offerings may not be an immediate fit for your local, we were wondering if you could provide some insights or recommendations.
        
        Given your experience and network within the labor community, you might be aware of other locals or unions in the area that could benefit from our services. Our offerings are designed to help unions better engage their membership, reduce expenses, and save valuable time for the executive team.
        
        If you know of any local unions that might find value in our services, we would greatly appreciate it if you could either forward this email to them or provide us with a contact name and details. Your assistance in connecting us with potential partners would be immensely valuable, and we assure you that we will handle any referrals with the utmost care and respect.

        As a suggestion, it might be helpful to save and print this email for future reference. Having a copy on hand could make it more convenient to revisit and share this message with your network when the opportunity arises.

        We understand the importance of collaboration within the labor movement, and your support in this matter could go a long way in strengthening the collective efforts of our unions.
  
        Thank you once again for your time and consideration. We look forward to any insights you may have, and please feel free to reach out if you have any questions or if there's anything else we can assist you with.
        `,
  };
  return emailTemplate;
};

export const waitReason1 = (unionData) => {
  const fullName = returnUserFullName();

  const emailTemplate = {
    subject:
      "Introducing YOUnified - Your Future Partner in Union Focused Solutions",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I trust this message finds you well. My name is ${fullName}, and I am reaching out on behalf of YOUnified, a dedicated Union-focused platform. As a representative of our Union local-focused company operating exclusively in Canada and the USA, we understand the unique challenges faced by labor unions in today's dynamic landscape.

        While we recognize that it might not be the opportune moment for your organization to consider a switch in providers, we believe in establishing connections and fostering relationships within the labor union community. Our commitment to supporting unions like yours is unwavering, and we would like to introduce you to YOUnified through our attached brochure.

        [Attach Brochure]

        We kindly request that you print this email and our brochure for your records. In the event that circumstances change, and you find yourself exploring new solutions, we would appreciate it if you consider YOUnified as your trusted partner. Our platform is designed to address the specific needs of unions, providing a comprehensive and tailored approach to enhance your operations.

        YOUnified is not just a platform; it's a commitment to the solidarity and strength that unions bring to their members. We are here to assist you in any way possible, whether it's answering questions, providing additional information, or offering a personalized demonstration of our platform.

        The labor union world is indeed a tight-knit community, and we believe that our paths will cross in the future. We look forward to the opportunity to collaborate and contribute to the success of your organization when the time is right for you.

        Feel free to reach out if you have any questions or if there's anything specific you would like to discuss. We appreciate your consideration and look forward to the possibility of working together.      
        `,
  };
  return emailTemplate;
};

export const waitReason2 = (unionData) => {
  const fullName = returnUserFullName();

  const emailTemplate = {
    subject:
      "Introducing YOUnified - Your Future Partner in Union Focused Solutions",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I trust this message finds you well. My name is ${fullName}, and I am reaching out on behalf of YOUnified, a dedicated Union-focused platform. As a representative of our Union local-focused company operating exclusively in Canada and the USA, we understand the unique challenges faced by labor unions in today's dynamic landscape.

        While we recognize that it might not be the opportune moment for your organization to consider a switch in providers, we believe in establishing connections and fostering relationships within the labor union community. Our commitment to supporting unions like yours is unwavering, and we would like to introduce you to YOUnified through our attached brochure.

        [Attach Brochure]

        We kindly request that you print this email and our brochure for your records. In the event that circumstances change, and you find yourself exploring new solutions, we would appreciate it if you consider YOUnified as your trusted partner. Our platform is designed to address the specific needs of unions, providing a comprehensive and tailored approach to enhance your operations.

        YOUnified is not just a platform; it's a commitment to the solidarity and strength that unions bring to their members. We are here to assist you in any way possible, whether it's answering questions, providing additional information, or offering a personalized demonstration of our platform.

        The labor union world is indeed a tight-knit community, and we believe that our paths will cross in the future. We look forward to the opportunity to collaborate and contribute to the success of your organization when the time is right for you.

        Feel free to reach out if you have any questions or if there's anything specific you would like to discuss. We appreciate your consideration and look forward to the possibility of working together.      
        `,
  };
  return emailTemplate;
};

export const waitReason3 = (unionData) => {
  const fullName = returnUserFullName();

  const emailTemplate = {
    subject:
      "Introducing YOUnified - Your Future Partner in Union Focused Solutions",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I trust this message finds you well. My name is ${fullName}, and I am reaching out on behalf of YOUnified, a dedicated Union-focused platform. As a representative of our Union local-focused company operating exclusively in Canada and the USA, we understand the unique challenges faced by labor unions in today's dynamic landscape.

        While we recognize that it might not be the opportune moment for your organization to consider a switch in providers, we believe in establishing connections and fostering relationships within the labor union community. Our commitment to supporting unions like yours is unwavering, and we would like to introduce you to YOUnified through our attached brochure.

        [Attach Brochure]

        We kindly request that you print this email and our brochure for your records. In the event that circumstances change, and you find yourself exploring new solutions, we would appreciate it if you consider YOUnified as your trusted partner. Our platform is designed to address the specific needs of unions, providing a comprehensive and tailored approach to enhance your operations.

        YOUnified is not just a platform; it's a commitment to the solidarity and strength that unions bring to their members. We are here to assist you in any way possible, whether it's answering questions, providing additional information, or offering a personalized demonstration of our platform.

        The labor union world is indeed a tight-knit community, and we believe that our paths will cross in the future. We look forward to the opportunity to collaborate and contribute to the success of your organization when the time is right for you.

        Feel free to reach out if you have any questions or if there's anything specific you would like to discuss. We appreciate your consideration and look forward to the possibility of working together.      
        `,
  };
  return emailTemplate;
};

export const waitLetter1 = (unionData) => {
  const emailTemplate = {
    subject: "Follow-up on Your Request for Information about Younified",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I hope this message finds you well. We're reaching out to follow up on your recent request for more information about the Younified platform. We understand that you may be busy, and we want to ensure that we've addressed all your questions and concerns.

        We have attempted to reach you by phone and email to provide the information you requested, but we haven't received a response. Your satisfaction is our priority, and we are committed to assisting you in making an informed decision.

        If there is any specific information you require, or if you have any questions, please let us know. We are more than willing to accommodate your schedule for a brief call or provide detailed documentation to assist in your evaluation.

        Your time and feedback are valuable to us, and we want to ensure that we meet your needs effectively. Please do not hesitate to reach out to us at your earliest convenience.

        Thank you for considering Younified, and we look forward to the opportunity to assist you further.
        `,
  };
  return emailTemplate;
};

export const waitLetter2 = (unionData) => {
  const emailTemplate = {
    subject: "Second Follow-up: Information on Younified",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I hope this message finds you well. We're sending a gentle reminder regarding your request for information about the Younified platform. We understand that your time is valuable, and we want to ensure that you have the details you need to make an informed decision.

        We have made multiple attempts to contact you via phone and email, but unfortunately, we haven't received a response. We remain eager to assist you and address any questions or concerns you may have.

        If you require specific information, have questions, or would like to schedule a call, please don't hesitate to reach out. We are here to accommodate your needs and ensure you have all the necessary information at your disposal.

        Your feedback is important to us, and we value the opportunity to serve your organization. Please consider getting in touch with us so that we can continue to assist you effectively.

        Thank you for your time and consideration.
        `,
  };
  return emailTemplate;
};

export const waitLetter3 = (unionData) => {
  const emailTemplate = {
    subject: "Final Follow-up: Information on Younified",
    isEmail: true,
    message: `Dear ${repName(unionData)},
        
        I hope this message finds you well. This is our final follow-up regarding your request for information about the Younified platform. We've made several attempts to connect with you, but unfortunately, we haven't received any response.

        We remain committed to assisting you in your evaluation of Younified. If there are any specific details, questions, or concerns you'd like to discuss, please let us know. Your satisfaction is of the utmost importance to us, and we want to ensure that you have all the resources you need.

        If there are any developments or if you wish to revisit the discussion at a later time, we'll be here to assist you. We appreciate your interest in Younified and the opportunity to serve your organization.

        Thank you for considering our platform, and we wish you all the best in your endeavors.
        `,
  };
  return emailTemplate;
};

export const waitLetter4 = (unionData) => {
  const emailTemplate = {
    subject: "Enhancing Union Engagement with Younified",
    isEmail: true,
    message: `Dear ${repName(unionData)},

    I hope this email finds you well. We're circling back once again regarding your inquiry about the Younified platform. Younified is an innovative platform tailored to the unique needs of unions, designed to increase member engagement, reduce operational expenses, and save valuable time for union executives and administrators.
    
    Despite our previous attempts to reach out, we haven't had the pleasure of connecting with you. We're committed to providing you with the information you require to make an informed decision about Younified.
    
    If there's any particular aspect of our platform you'd like more information on, or if you have any concerns we can address, please don't hesitate to let us know. We're more than willing to accommodate your preferred method of communication, whether it's via email, phone, or even a virtual meeting.
    
    Your satisfaction is paramount to us, and we're dedicated to assisting you every step of the way. We appreciate your consideration and eagerly await the opportunity to support your organization's goals.
    
    Thank you for your time and attention.
    `,
  };
  return emailTemplate;
};

export const waitLetter5 = (unionData) => {
  const emailTemplate = {
    subject: "Your Input Matters - Let Us Help You Further",
    isEmail: true,
    message: `Dear ${repName(unionData)},
    
    I hope this email finds you doing well. Despite our efforts to connect with you, we haven't received a response. We're committed to assisting you in any way we can. Younified is an exciting platform designed to revolutionize how unions operate, offering streamlined communication, enhanced member engagement, and optimized operational efficiency.

    Your feedback is invaluable to us, and we're eager to hear from you. Please don't hesitate to reach out if there's anything we can do to facilitate your decision-making process.
    
    Thank you for considering Younified. We appreciate the opportunity to be of service to you and your organization.
    `,
  };
  return emailTemplate;
};

export const waitLetter6 = (unionData) => {
  const emailTemplate = {
    subject: "Continuing Support for Your Evaluation",
    isEmail: true,
    message: `Dear ${repName(unionData)},

    I trust this email finds you well. Despite our previous attempts to connect with you, we haven't had the pleasure of speaking with you directly. We understand that timing is crucial, and we're here to assist you whenever you're ready.
    
    Younified is a great platform that will benefit your union by streamlining communication, increasing member engagement, and optimizing operational efficiency.
    
    Whether you require further information, have specific questions, or would like to discuss your organization's unique requirements, we're here to help. Your satisfaction is our priority, and we're committed to providing you with the support you need to make an informed decision.
    
    Please feel free to reach out to us at your earliest convenience. We're eager to continue supporting your evaluation and to assist you in any way we can.
    
    Thank you for considering our platform. We look forward to the opportunity to work with you.
    `,
  };
  return emailTemplate;
};

export const waitLetter7 = (unionData) => {
  const emailTemplate = {
    subject: "Elevate Your Union's Potential with Younified",
    isEmail: true,
    message: `Dear ${repName(unionData)},

    I hope this message finds you well. Younified presents a transformative opportunity for your union, offering unparalleled tools to streamline operations, boost member engagement, and enhance communication.
    
    Despite our previous attempts to connect, we haven't had the opportunity to discuss how Younified can benefit your union specifically. We understand the importance of finding the right solution, and we're here to guide you through every step of the process.
    
    Please let us know if you'd like to explore how Younified can empower your union to reach its full potential. We're excited about the opportunity to work with you.
    
    Thank you for considering Younified.
    `,
  };
  return emailTemplate;
};

export const waitLetter8 = (unionData) => {
  const emailTemplate = {
    subject: "Unlock the Power of Younified for Your Union",
    isEmail: true,
    message: `Dear ${repName(unionData)},

    I trust this email finds you well. Younified is more than just a platform – it's a catalyst for change within your union. By leveraging Younified's comprehensive features, you can streamline operations, foster collaboration, and strengthen member relationships.
    
    Despite our previous attempts to connect, we haven't had the chance to discuss how Younified aligns with your union's goals. We're eager to demonstrate the value our platform can bring to your organization.
    
    Please don't hesitate to reach out if you're interested in exploring how Younified can drive success for your union. We're here to support you every step of the way.

    Thank you for considering Younified.
    `,
  };
  return emailTemplate;
};

export const waitLetter9 = (unionData) => {
  const emailTemplate = {
    subject: "Revolutionize Your Union's Operations with Younified",
    isEmail: true,
    message: `Dear ${repName(unionData)},

    I hope this email finds you well. Younified offers a comprehensive solution to streamline your union's operations, enhance member engagement, and improve communication across all levels.
    
    Despite our previous attempts to connect, we haven't had the chance to discuss how Younified can address the specific challenges facing your union. We're committed to understanding your needs and tailoring our solution to meet them effectively.
    
    If you're ready to revolutionize your union's operations and drive growth, we're here to help. Let's schedule a conversation to explore how Younified can make a difference for your organization.
    
    Thank you for considering Younified.
    `,
  };
  return emailTemplate;
};

export const waitLetter10 = (unionData) => {
  const emailTemplate = {
    subject: "Empower Your Union with Younified's Innovative Features",
    isEmail: true,
    message: `Hello ${repName(unionData)},

    I hope this email finds you well. Younified is more than just a platform – it's a game-changer for unions like yours. With its innovative features and user-friendly interface, Younified empowers unions to streamline operations, enhance member engagement, and achieve their organizational goals.
    
    Despite our previous attempts to connect, we haven't had the opportunity to discuss how Younified can revolutionize your union's operations. We're passionate about helping unions thrive, and we're here to support you every step of the way.
    
    If you're ready to unlock the full potential of your union, let's schedule a demo to explore how Younified can make a difference for your organization.
    
    Thank you for considering Younified.
    `,
  };
  return emailTemplate;
};

export const waitLetter11 = (unionData) => {
  const emailTemplate = {
    subject: "Discover the Younified Advantage for Your Union",
    isEmail: true,
    message: `Hello ${repName(unionData)},

    I hope you're doing well. Younified offers a comprehensive solution to address the unique challenges facing unions today. With its intuitive design and robust features, Younified empowers unions to streamline operations, strengthen member engagement, and drive organizational growth.
    
    Despite our previous attempts to connect, we haven't had the opportunity to demonstrate how Younified can benefit your union. We're committed to understanding your needs and delivering tailored solutions that drive results.
    
    If you're ready to take your union to the next level, we invite you to explore the Younified advantage. Schedule a consultation today to learn how Younified can transform your organization.
    
    Thank you for considering Younified.
    `,
  };
  return emailTemplate;
};

export const waitLetter12 = (unionData) => {
  const emailTemplate = {
    subject: "Transform Your Union with Younified's Proven Solution",
    isEmail: true,
    message: `Hello ${repName(unionData)},

    I trust this email finds you well. Younified is the solution your union has been searching for. With its powerful tools and advanced capabilities, Younified empowers unions to streamline operations, increase member engagement, and drive meaningful change.
    
    Despite our previous attempts to connect, we haven't had the opportunity to discuss how Younified can support your union's goals. We're passionate about helping unions succeed, and we're committed to providing you with the support and guidance you need to thrive.
    
    If you're ready to transform your union and unlock new opportunities for growth, we're here to help. Schedule a demo today to experience the Younified difference for yourself.
    
    Thank you for considering Younified.
    `,
  };
  return emailTemplate;
};

export const whoLetter = (unionData) => {
  const emailTemplate = {
    subject: "Unlocking the Potential of Younified for Your Union",
    isEmail: true,
    message: `Dear ${repName(unionData)},

        I hope this email finds you well. My name is ${fullName}, and I represent Younified, a platform designed to empower unions like yours to thrive in today's dynamic landscape. As the President of your union, you play a pivotal role in guiding its success, and we believe Younified can be an invaluable tool to achieve your goals.

        Allow me to introduce Younified:

        What is Younified?

        Younified is an innovative platform tailored to the unique needs of unions. Our platform is built with the primary goal of helping unions increase member engagement, reduce operational expenses, and save valuable time for union executives and administrators. We provide a centralized hub where your union members can connect, collaborate, and stay informed.

        Our Offer to You:

        We're excited to offer you an opportunity to explore Younified further through a personalized demonstration. This demonstration will allow you to see firsthand how our platform can benefit your union. Whether you have questions, specific requirements, or simply want to chat and learn more about what Younified can do for you, we're here to help.

        No Obligation, Just a Conversation:

        There's absolutely no obligation associated with this offer. We invite you to connect with us at your convenience for an informal chat. This conversation will give us the chance to understand your union's unique needs better, and it provides you with an opportunity to learn about Younified and how it aligns with your objectives.

        Engaging Every Member:

        Even if some of your members prefer not to be actively involved or stay informed about union activities, Younified can still reach out to them. Our platform allows you to communicate and engage with all members, ensuring that everyone's needs and preferences are taken into account.

        Developed with the Expertise of Union Executives:

        It's worth noting that Younified was developed with the invaluable input of over 200 union executives. Their insights and feedback have been instrumental in shaping the platform into what it is today—a powerful tool designed specifically to address the unique challenges faced by unions.

        To schedule a call or request a demonstration, simply reply to this email or give us a call at ${phoneNumber}. We are flexible and will work with your schedule to ensure a convenient time.

        We look forward to the possibility of working with your union and helping you achieve greater member engagement, cost savings, and time efficiency. Your union's success is our priority.

        Thank you for considering Younified, and we hope to hear from you soon.`,
  };
  return emailTemplate;
};

export const whatLetter = (unionData) => {
  const emailTemplate = {
    subject: "Unlocking the Potential of Younified for Your Union",
    isEmail: true,
    message: `Dear ${repName(unionData)},

        I trust this message finds you well. I'm ${fullName}, representing Younified, a revolutionary platform poised to transform your union's operations. As the key figure steering your union's success, we believe Younified can play a pivotal role in achieving your goals.
        
        Member Engagement Beyond Expectations:
        Younified, tailored for unions, redefines member engagement. It fosters a dynamic hub where members connect, collaborate, and stay informed. Be it updates, discussions, or events, Younified establishes a vibrant online community, keeping members actively involved.
        
        Efficiency that Drives Savings:
        Younified streamlines administrative tasks, from dues collection to event management, translating to significant cost savings. By reducing manual processes, it ensures resources are directed toward your union's core mission.
        
        Time Savings for Your Executives:
        Designed to save valuable time, Younified offers tools simplifying member data management, grievance handling, and event organization. This empowers leadership to focus on strategic decisions and driving member engagement.
        
        Enhanced Re-Election Opportunities:
        Younified showcases positive impacts, increasing support for current leadership. Executives demonstrating improved member engagement and cost savings are more likely to be re-elected.
        
        Built by Union Executives for Union Success:
        Shaped by collaboration with over 200 union executives, Younified addresses unique union challenges effectively.
        
        Follow-Up on Previous Email:
        We understand your busy schedule and appreciate your time. If you've had a chance to review our previous email, we'd love to hear your thoughts or address any questions you may have.
        
        No Obligation, Just a Conversation:
        Explore Younified through a personalized demonstration. Whether you have questions or specific requirements, we're here to assist. To schedule, reply to this email or call ${phoneNumber}. We'll work with your schedule.
        
        We look forward to the possibility of working with your union, prioritizing extraordinary member engagement, cost savings, and time efficiency.
        
        Thank you for considering Younified. We hope to hear from you soon.
        `,
  };
  return emailTemplate;
};

export const willCallLetter = (unionData) => {
  //const presidentPhoneNumber = unionData.contactNumber1 ? unionData.contactNumber1 : unionData.officePhoneNumber;

  const emailTemplate = {
    subject: "Follow-Up: Younified - A Personalized Call Awaits",
    isEmail: true,
    message: `Dear ${unionData.contactName || "Union Contact"},
        
            I hope this email finds you well. I wanted to follow up on my previous email regarding Younified and the potential it holds for your union.

            As mentioned earlier, my name is ${fullName}, and I represent Younified. We have been eagerly looking forward to the opportunity to discuss how Younified can benefit your union, and we greatly value your time.

            Scheduled Call:

            I wanted to inform you that I will be giving you a call at your earliest convenience to discuss Younified in more detail. I have your contact number on file as ${phoneNumber}, and I will use this number to reach out to you.

            Your Time, Your Pace:

            Our aim is to provide you with all the information you need to make an informed decision, and this call is intended to be an open and exploratory conversation. There's no pressure or obligation on your part; it's simply an opportunity for us to learn more about your union's specific needs and to address any questions or concerns you might have.

            Learning Together:

            We believe that there is no harm in having a conversation and learning from each other. Your insights and requirements are of utmost importance to us as we strive to tailor Younified to meet the unique needs of your union. Likewise, we are excited to share our expertise and how Younified can contribute to the growth and efficiency of your organization.

            Bringing Younified to Your Union:

            Younified has been designed with the collaboration of over 200 union executives, and we are confident it can make a positive impact on your union's member engagement, efficiency, and overall success.

            Please feel free to suggest a convenient time for our call, or I can propose a few times based on your availability. We are here to accommodate your schedule.

            Thank you once again for considering Younified. We look forward to our conversation and the possibility of working together to enhance your union's effectiveness.

            If you have any immediate questions or need to reach out before our scheduled call, you can also contact me at ${email}.
        `,
  };
  return emailTemplate;
};

export const appointmentConfirmation = ({
  unionData,
  appointmentDate,
  startTime,
  endTime,
}) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    appointmentDate,
  );

  const timeString = unionData.timeZone
    ? `${formatTimeToLocalTime(startTime, endTime, unionData.timeZone).formattedStartTime} - ${formatTimeToLocalTime(startTime, endTime, unionData.timeZone).formattedEndTime} (${unionData.timeZone})`
    : `${startTime} - ${endTime} (Canada/Eastern)`;

  const emailTemplate = {
    subject: "Confirmation: Younified Presentation Appointment",
    message: `Dear ${repName(unionData)},

        I hope this message finds you well. We are thrilled to confirm your appointment for a presentation of Younified, the innovative platform designed to enhance your union's capabilities and success.

        𝗔𝗽𝗽𝗼𝗶𝗻𝘁𝗺𝗲𝗻𝘁 𝗗𝗲𝘁𝗮𝗶𝗹𝘀:

        𝗗𝗮𝘁𝗲: ${formattedDate}

        𝗧𝗶𝗺𝗲: ${timeString}

        𝗗𝘂𝗿𝗮𝘁𝗶𝗼𝗻: Approximately ${calculateTimeDifference(startTime, endTime)}

        𝗣𝗿𝗲𝘀𝗲𝗻𝘁𝗲𝗿: ${loggedInUser()}

        𝗠𝗲𝗲𝘁𝗶𝗻𝗴 𝗟𝗶𝗻𝗸: https://us02web.zoom.us/j/86016070543?pwd=WVNGaDVPWlhCVHV6U0NZUkNlcmhtZz09

        𝗔𝗴𝗲𝗻𝗱𝗮:

        During our presentation, we will provide an in-depth demonstration of Younified's features and functionalities, showcasing how it can help increase member engagement, decrease expenses, and save valuable time for union executives and administrators. We will also be open to any questions or specific requirements you may have.

        𝗠𝗲𝗲𝘁𝗶𝗻𝗴 𝗣𝗿𝗲𝗽𝗮𝗿𝗮𝘁𝗶𝗼𝗻:

        To make the most of our time together, please ensure that you have access to a computer or device with an internet connection and audio capabilities, as well as a web browser for the virtual presentation, if applicable.

        𝗖𝗼𝗻𝘁𝗮𝗰𝘁 𝗜𝗻𝗳𝗼𝗿𝗺𝗮𝘁𝗶𝗼𝗻:

        If you have any questions or need to reschedule for any reason, please don't hesitate to reach out to us at ${email} or ${phoneNumber}. We are here to assist you in any way possible.

        𝗪𝗲 𝗟𝗼𝗼𝗸 𝗙𝗼𝗿𝘄𝗮𝗿𝗱 𝘁𝗼 𝗠𝗲𝗲𝘁𝗶𝗻𝗴 𝗬𝗼𝘂:

        We value your time and your commitment to exploring Younified, and we are dedicated to making this presentation informative and engaging.

        Until then, please accept our warmest regards, and we'll see you on ${formattedDate}!
        `,
    isEmail: true,
  };
  return emailTemplate;
};

export const thankYouLetter = ({ unionData }) => {
  const emailTemplate = {
    subject: "Thank You for Your Time - Younified Presentation",
    isEmail: true,
    message: `Dear ${repName(unionData)},

I wanted to extend my heartfelt thanks to you for taking the time to join our presentation on Younified. It was a pleasure meeting with you and discussing how our platform can benefit your union.

Your engagement and thoughtful questions during the presentation were greatly appreciated. We are genuinely excited about the possibility of partnering with your union to help you achieve your goals of increasing member engagement, reducing expenses, and saving valuable time for your union executives and administrators.

Key Takeaways from the Presentation:

During our meeting, we highlighted the key features and functionalities of Younified, showcasing how it can transform your union's operations and enhance its overall effectiveness. We also addressed your specific questions and requirements, which are invaluable in tailoring our solutions to meet your needs.

Next Steps:

We understand that decisions like these require careful consideration. You will find the presentation materials attached to this email for your reference. If you have any further questions, need additional information, or would like to discuss anything in more detail, please do not hesitate to reach out to us. We are here to assist you every step of the way.

Your Input Matters:

Your feedback and input are of the utmost importance to us. As we move forward, we look forward to collaborating with you and your team to ensure that Younified is aligned perfectly with your union's vision and objectives.

Stay Connected:

To keep you updated on the latest developments and news about Younified, we encourage you to connect with us on social media and subscribe to our newsletter. This way, you can stay informed about how our platform is making a difference in the world of unions.

Thank you once again for considering Younified as a potential partner for your union. We are excited about the possibility of working together and contributing to your union's continued success.
        `,
  };
  return emailTemplate;
};



export const preDemoLetter = ({ unionData }) => {
  const emailTemplate = {
    subject: "Pre-Demo Checklist",
    isEmail: true,
    message: `Local: ${unionData.union} ${unionData.localNumber}

Contact:

Do the executive have union email   Yes      No       address

Do they have an office location

Do they have phone extensions

What is the primary need

How Many Members

Web Site

Any Important Information

`,
  };
  return emailTemplate;
};
