import users from "../data/users.json";

export function loggedInUser() {
  const name = localStorage.getItem("loggedInUser");

  if (name) {
    // Capitalize the first letter of the name
    const capitalizedName = capitalizeFirstLetter(name);
    return capitalizedName;
  } else {
    // Handle the case when 'loggedInUser' is not found in localStorage.
    return null;
  }
}

// Function to capitalize the first letter of a string
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function adminStatus() {
  const adminStatus = localStorage.getItem("isAdmin");
  if (adminStatus) {
    return adminStatus;
  } else {
    // Handle the case when 'isAdmin' is not found in localStorage.
    return null;
  }
}

export const findLoggedInUser = (rep) => {
  const loggedInUsername = localStorage.getItem("loggedInUser");
  if (rep) {
    const loggedInUser = users.find(
      (user) => user.username === rep.toLowerCase(),
    );
    return loggedInUser;
  }
  if (loggedInUsername) {
    const loggedInUser = users.find(
      (user) => user.username === loggedInUsername,
    );
    return loggedInUser;
  }
  return null; // Return null if no logged-in user is found
};

export const returnUserFullName = () => {
  const loggedInUsername = localStorage.getItem("loggedInUser");
  if (loggedInUsername) {
    const loggedInUser = users.find(
      (user) => user.username === loggedInUsername,
    );
    return `${loggedInUser.firstname} ${loggedInUser.lastname}`;
  }
  return null; // Return null if no logged-in user is found
};

export const returnFirstName = () => {
  const loggedInUsername = localStorage.getItem("loggedInUser");
  if (loggedInUsername) {
    const loggedInUser = users.find(
      (user) => user.username === loggedInUsername,
    );
    return loggedInUser.firstname;
  }
  return null; // Return null if no logged-in user is found
};
