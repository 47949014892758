import React, { useState } from "react";
import axios from "axios";
import Modal from "./Modal";
import { resetLeadHistory } from "../../helpers/sleep";
import { increaseCount } from "../../helpers/userActionHistory";
import { wakeUpCall } from "../../helpers/scriptTemplates/phoneTemplates";
import { activateSleepStrategy } from "../../helpers/sleep";
import {
  noLetterNoBudget as noLetter1,
  noLetterNotInterested as noLetter2,
  noLetterNotNeeded as noLetter3,
  noLetterReferral as noLetter4,
} from "../../helpers/scriptTemplates/emailTemplates";
import { FaEnvelope } from "react-icons/fa";

export default function NoModal({ closeModal, data, refreshSuspectList }) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const unionLocal = `${data.union} ${data.localNumber}`;
  const [selectedContact, setSelectedContact] = useState(
    data.primaryContact || 1,
  );

  const deleteAllTasks = async () => {
    try {
      const params = {
        localNumber: data.localNumber,
        union: data.union,
      };
  
      const response = await axios.get(
        "https://unionapi.netlify.app/.netlify/functions/api/tasks",
        { params },
      );
  
      if (response.data.length > 0) {
        
        const tasksToDelete = response.data.filter(task => task.transferable!==true);
        
        if (tasksToDelete.length > 0) {
          const taskIdsToDelete = tasksToDelete.map((task) => task._id);
  
          await Promise.all(
            taskIdsToDelete.map((taskId) =>
              axios.delete(
                `https://unionapi.netlify.app/.netlify/functions/api/tasks/${taskId}`,
              ),
            ),
          );
  
          console.log("Tasks deleted successfully");
        } else {
          console.log("No matching tasks found");
        }
      } else {
        console.log("No tasks found");
      }
    } catch (error) {
      console.error("Error deleting tasks:", error);
    }
  };
  

  const updateHistory = async (dataId, note, nextContactValue) => {
    try {
      //increase calls made by 1
      increaseCount(data.assignedRep.toLowerCase(), "callsMade");

      const currentDate = new Date().toISOString();

      const newHistoryEntry = {
        note: note || "Sleep Strategy",
        reason: `Sleep strategy for ${nextContactValue}`,
        date: currentDate,
      };

      const existingData = await axios.get(
        `https://unionapi.netlify.app/.netlify/functions/api/unions/${dataId}`,
      );
      const existingHistory = existingData.data.history || [];

      const updatedHistory = [...existingHistory, newHistoryEntry];

      await axios.put(
        `https://unionapi.netlify.app/.netlify/functions/api/unions/${dataId}`,
        {
          history: updatedHistory,
        },
      );

      console.log("History updated successfully");
    } catch (error) {
      console.error("Error updating history:", error);
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <button
        onClick={closeModal}
        className="absolute top-2 right-2 p-2 text-gray-600 hover:text-gray-800"
      >
        <span className="text-3xl">&times;</span>
      </button>

      <h2 className="text-xl font-semibold mb-4">
        Activate sleep strategy for {unionLocal}
      </h2>

      <div className="mt-4">
        <label
          htmlFor="nextContact"
          className="block text-gray-700 font-semibold"
        >
          Next Contact
        </label>
        <select
          id="nextContact"
          name="nextContact"
          className="block w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-300 focus:outline-none"
        >
          <option value="1 month">1 month</option>
          <option value="3 months">3 months</option>
          <option value="6 months">6 months</option>
          <option value="1 year">1 year</option>
        </select>
      </div>

      <div className="mt-4">
        <label className="block text-gray-700 font-semibold">
          Select Contact
        </label>
        <div className="flex mt-2">
          <label className="inline-flex items-center mr-4">
            <input
              type="radio"
              value={1}
              checked={selectedContact === 1}
              onChange={() => setSelectedContact(1)}
              className="form-radio h-5 w-5 text-blue-500"
              disabled={data.contactEmail != null ? false : true}
            />
            <p className="ml-2">
              {data.contactName
                ? `${data.contactName} ${data.contactJobTitle && `(${data.contactJobTitle})`}`
                : `Contact 1`}{" "}
              {(!data.contactEmail || !data.contactEmail) && (
                <span className="font-bold">⚠️ Missing email</span>
              )}
            </p>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              value={2}
              checked={selectedContact === 2}
              onChange={() => setSelectedContact(2)}
              className="form-radio h-5 w-5 text-blue-500"
              disabled={data.alternateContactEmail != null ? false : true}
            />
            <p className="ml-2">
              {data.alternateContactName
                ? `${data.alternateContactName} ${data.alternateContactJobTitle && `(${data.alternateContactJobTitle})`}`
                : `Contact 2`}{" "}
              {!data.alternateContactEmail && (
                <span className="font-bold">⚠️ Missing email</span>
              )}
            </p>
          </label>
        </div>
      </div>

      <div className="flex gap-x-4 mt-2">
        <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://outlook.office.com/mail/deeplink/compose?to=${selectedContact === 1 ? (data.contactEmail ? data.contactEmail : data.email) : data.alternateContactEmail}&subject=${encodeURIComponent(noLetter1(data).subject)}&body=${encodeURIComponent(noLetter1(data).message)}`}
            className="flex items-center gap-x-2"
          >
            <FaEnvelope />
            Send 'No Budget' Email
          </a>
        </button>

        <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://outlook.office.com/mail/deeplink/compose?to=${selectedContact === 1 ? (data.contactEmail ? data.contactEmail : data.email) : data.alternateContactEmail}&subject=${encodeURIComponent(noLetter2(data).subject)}&body=${encodeURIComponent(noLetter2(data).message)}`}
            className="flex items-center gap-x-2"
          >
            <FaEnvelope />
            Send 'Not Interested' Email
          </a>
        </button>

        <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://outlook.office.com/mail/deeplink/compose?to=${selectedContact === 1 ? (data.contactEmail ? data.contactEmail : data.email) : data.alternateContactEmail}&subject=${encodeURIComponent(noLetter3(data).subject)}&body=${encodeURIComponent(noLetter3(data).message)}`}
            className="flex items-center gap-x-2"
          >
            <FaEnvelope />
            Send 'Not Currently Needed' Email
          </a>
        </button>

        <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://outlook.office.com/mail/deeplink/compose?to=${selectedContact === 1 ? (data.contactEmail ? data.contactEmail : data.email) : data.alternateContactEmail}&subject=${encodeURIComponent(noLetter4(data).subject)}&body=${encodeURIComponent(noLetter4(data).message)}`}
            className="flex items-center gap-x-2"
          >
            <FaEnvelope />
            Send 'Ask for Referral' Email
          </a>
        </button>
      </div>

      <div className="mt-4">
        <label htmlFor="note" className="block text-gray-700 font-semibold">
          Notes
        </label>
        <textarea
          id="note"
          name="note"
          rows="4"
          placeholder="Reason for denying services"
          className="block w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-300 focus:outline-none resize-none"
        ></textarea>
      </div>

      <div className="mt-6 flex justify-end">
        <button
          className="px-4 py-2 border-2 text-zinc-800 hover:bg-zinc-100  border-zinc-800 rounded mr-4"
          onClick={closeModal}
        >
          No, Don't activate sleep strategy
        </button>
        <button
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          onClick={() =>
            activateSleepStrategy({
              data,
              resetLeadHistory,
              wakeUpCall,
              setIsButtonDisabled,
              deleteAllTasks,
              updateHistory,
              closeModal,
              refreshSuspectList,
            })
          }
          disabled={isButtonDisabled}
        >
          {isButtonDisabled ? "Activating..." : "Activate sleep strategy"}
        </button>
      </div>
    </Modal>
  );
}
