import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Modal from "./Modal";
import addHistory from "../../helpers/historyHelper";
import { RedButton, GreenButton } from "../buttons/button";
import { SendToDataEntry } from "../../helpers/sendToDataEntry";

export default function SendToDataEntryModal({
  closeModal,
  data,

}) {

  const [reason, setReason] = useState("Wrong Information"); // Default action
  const [note, setNote] = useState("");
  const navigate = useNavigate();
  const confirmReschedule = async () => {
    try {
      // Assuming SendToDataEntry returns a promise
      

      await SendToDataEntry({ data: data, reason: reason, note: note });
      await addHistory({
        id: data._id,
        note: `Moved to Data Entry Site`,
        reason: reason,
      });
      // If the data entry operation is successful, navigate to /dashboard
      await navigate('/dashboard');
    } catch (error) {
      // Handle errors if any
      console.error('Error occurred during data entry:', error);
    } finally {
      closeModal();
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <h2 className="text-xl font-bold">Send to Data Entry</h2>
      
        <>
          <div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Note
              </label>
              <select
                className="block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="actionsTaken"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              >
                <option value="Wrong Information">Wrong Information</option>
                <option value="Missing Information">Missing Information</option>
                <option value="French Local">French Local</option>
                <option value="Phone number wrong">Phone number wrong</option>
                <option value="Email bounce back/incorrect">Email bounce back/incorrect</option>
                <option value="Old data">Old data</option>
                <option value="Incomplete Data">Incomplete Data</option>
                <option value="Local does not exist">Local does not exist</option>
                <option value="Contact is no longer with the local">Contact is no longer with the local</option>
                <option value="No contact information available for this local">No contact information available for this local</option>
                <option value="Local does not want to be contacted">Local does not want to be contacted</option>
                <option value="Other">Other: Please provide reason</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Details (optional)
              </label>
              <textarea
                className="block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="details"
                value={note} // Assuming that `note` is the state you want to bind to the textarea
                onChange={(e) => setNote(e.target.value)} // Make sure to define setNote in your component
                placeholder="Enter details here..."
              />
            </div>
            <div className="flex gap-x-2 mt-16 justify-end">
              <RedButton action={closeModal}>Cancel</RedButton>
              <GreenButton action={confirmReschedule}>Send to Data Entry</GreenButton>
            </div>
          </div>
        </>
      
    </Modal>
  );
}
