import React from "react";

export default function Info({ title, subtitle }) {
  return (
    <div className="my-4">
      <p className="font-bold">{title}</p>
      <p>
        {subtitle ? (
          subtitle
        ) : (
          <span className="text-yellow-600">Not Available</span>
        )}
      </p>
    </div>
  );
}
